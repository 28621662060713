/**
 * Page Style
 */

/* common */
.text {
  margin: 1em 0;
}
.align-center {
  text-align: center;
}
.list {
  list-style: disc;
  margin: 0 0 0 1.25em;
  padding: 0;
  &--none {
    list-style: none;
    margin-left: 0;
  }
}
/* page-header */
.page-header {
  position: relative;
  height: 98px;
  background-image: url(../img/shared/bg_page_header_sp.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  overflow: hidden;
  @include mq-above(md) {
    background-image: url(../img/shared/bg_page_header.svg);
    background-size: 100% 100%;
    background-position: center center;
    height: 196px;
  }
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    &:before {
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      .page-blog &,
      .single-post &,
      .category &,
      .tag & {
        bottom: 20px;
        left: 50%;
        transform: translate(-136%, 0);
        width: 352px * .5;
        height: 142px * .5;
        background-image: url(../img/blog/bg_jinbeizame.svg);
        background-size: cover;
        @include mq-above(md) {
          bottom: 20px;
          transform: translate(-140%, 0);
          width: 352px;
          height: 142px;
        }
      }
      .parent-contact & {
        top: -5px;
        left: 50%;
        transform: translate(60px, 0);
        width: 243px * .5;
        height: 156px * .5;
        background-image: url(../img/contact/bg_fish.svg);
        background-size: cover;
        @include mq-above(md) {
          top: -10px;
          transform: translate(180px, 0);
          width: 243px;
          height: 156px;
        }
      }
    }
  }
}
.page-title {
  position: relative;
  margin: -6px 0 0 0;
  color: $text-color-white;
  font-size: calc(40/750 * 100vw);
  line-height: 1.2;
  text-align: center;
  @include mq-above(xxs) {
    font-size: 2.0rem;
  }
  @include mq-above(md) {
    margin-top: -12px;
    font-size: 4.0rem;
  }
}
/* page-content */
.page-content {
  padding-top: 48px;
  padding-bottom: 60px;
  @include mq-above(md) {
    padding-top: 56px;
    padding-bottom: 120px;
  }
}
/* section */
.section:nth-child(n+2) {
  margin-top: 20px;
  @include mq-above(md) {
    margin-top: 40px;
  }
}
/* title */
.primary-title {
  margin: 0;
}
.secondary-title {
  margin: 1em 0;
}
/* form */
.contact-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 23px 0;
  font-size: calc(32/750 * 100vw);
  font-weight: bold;
  line-height: (34/20);
  text-align: center;
  @include mq-above(xxs) {
    font-size: 1.6rem;
  }
  @include mq-above(md) {
    margin: 0 0 30px 0;
    height: 60px;
    font-size: 2.0rem;
  }
  &--thanks {
    color: $key-color;
    font-size: calc(40/750 * 100vw);
    line-height: (46/26);
    @include mq-above(md) {
      font-size: 2.6rem;
    }
  }
}
.contact-step {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  > li {
    position: relative;
    width: 120px;
    height: 120px;
    @include mq-above(md) {
      width: 150px;
      height: 150px;
    }
    &.is-current {
      width: 150px;
      width: 150px;
      @include mq-above(md) {
        width: 180px;
        width: 180px;
      }
    }
  }
}
.step-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $text-color-white;
  z-index: 1;
  @include mq-above(md) {
    font-size: 2.2rem;
    line-height: (24/22);
  }
}
.decagon {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    margin: auto;
    width: 70px;
    height: 70px;
    background-image: url(../img/shared/bg_decagon.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @include mq-above(md) {
      width: 103px;
      height: 103px;
    }
  }
  &.is-current:before {
    width: 91px;
    height: 91px;
    background-image: url(../img/shared/bg_decagon_blue.svg);
    @include mq-above(md) {
      width: 133px;
      height: 133px;
    }
  }
}
.contact-body {
  margin: 43px 0 0 0;
  @include mq-above(md) {
    margin: 80px 0 0 0;
  }
}
.f-item {
  margin-top: 2px;
  @include mq-above(md) {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
  }
}
.f-key {
  display: flex;
  padding: calc(30/750 * 100vw);
  padding: 15px;
  background-color: rgba(0, 138, 230, 0.25);
  @include mq-above(md) {
    padding: 20px 30px;
    width: 286px;
  }
}
.f-label {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: calc(34/750 * 100vw);
  font-weight: bold;
  line-height: (48/34);
  @include mq-above(xxs) {
    font-size: 1.7rem;
  }
  @include mq-above(md) {
    justify-content: space-between;
    font-size: 2.0rem;
  }
  > .required {
    margin-left: 10px;
    padding: 3px;
    border-radius: 4px;
    background-color: #da1d1b;
    color: $text-color-white;
    font-size: 1.5rem;
    line-height: 1;
    font-weight: normal;
    @include mq-above(md) {
      margin-left: 0;
    }
  }
}
.f-value {
  margin-top: 2px;
  padding: 15px;
  background-color: rgba(0, 138, 230, 0.1);
  @include mq-above(md) {
    flex: 1 0 auto;
    margin: 0 0 0 4px;
    padding: 20px;
  }
}
.input-text {
  appearance: none;
  padding: 15px 12px;
  border: 2px solid $border-color-gray;
  border-radius: 0;
  width: 100%;
  background-color: #ffffff;
  font-size: 1.6rem;
  outline: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  @include mq-above(md) {
    padding: 14px 20px;
    font-size: 1.8rem;
  }
  &:focus {
    background-color: #fffff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }
  &::-webkit-input-placeholder {
    padding-top: .15em;
    line-height: 1;
  }
}
.input-textarea {
  appearance: none;
  resize: none;
  display: block;
  padding: 15px 12px;
  border: 2px solid $border-color-gray;
  border-radius: 0;
  width: 100%;
  height: 10em;
  background-color: #ffffff;
  font-size: 1.6rem;
  outline: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  @include mq-above(md) {
    padding: 14px 20px;
    height: 16em;
    font-size: 1.8rem;
  }
  &:focus {
    background-color: #fffff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }
}
.mw_wp_form .f-value .vertical-item + .vertical-item {
  margin-top: 15px;
}
.input-radio {
  display: none;
  & + label {
    position: relative;
    display: flex;
    align-items: center;
  }
  &:checked + label > .input-radio-disc:before {
    background-color: $key-color;
    transform: translate(-50%,-50%) scale(1);
  }
}
.input-radio-disc {
  position: relative;
  margin-right: calc(28/750 * 100vw);
  border: 2px solid $border-color-gray;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  pointer-events: none;
  user-select: none;
  @include mq-above(xxs) {
    margin-right: 14px;
  }
  @include mq-above(md) {
    border-width: 2px solid $border-color-gray;
    width: 34px;
    height: 34px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) scale(0);
    display: block;
    margin: auto;
    border-radius: 50%;
    width: 50%;
    height: 50%;
    transition: transform .2s ease-in-out;
  }
}
.input-checkbox {
  display: none;
  & + label {
    position: relative;
    display: flex;
    align-items: center;
  }
  &:checked + label > .input-checkbox-box:before {
    transform: translate(-50%,-50%) scale(1);
  }
}
.input-checkbox-box {
  position: relative;
  display: block;
  margin-right: calc(18/750 * 100vw);
  border: 2px solid $border-color-gray;
  width: 36px;
  height: 36px;
  // pointer-events: none;
  // user-select: none;
  @include mq-above(xxs) {
    margin-right: 9px;
  }
  @include mq-above(md) {
    margin-right: 20px;
    width: 40px;
    height: 40px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) scale(0);
    display: block;
    margin: auto;
    width: 20px;
    height: 18px;
    background-image: url(../img/shared/icon_check.svg);
    background-repeat: no-repeat;
    background-size: 20px 18px;
    transition: transform .2s ease-in-out;
  }
  + .mwform-checkbox-field-text {
    flex: 1 0 auto;
    line-height: (48/32);
  }
}
.privacy-check {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 0 0;
  width: 100%;
  @include mq-above(md) {
    margin: 60px 0 0 0;
  }
  > p {
    margin: 0;
  }
}
.f-button-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px 0 0 0;
  @include mq-above(md) {
    flex-direction: row;
    margin: 60px 0 0 0;
  }
}
/* blog */
.blog-list {
  padding-top: 30px;
  @include mq-above(md) {
    padding-top: 70px;
  }
  &-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: calc(38/750 * 100vw);
    @include mq-above(xxs) {
      font-size: 1.9rem;
    }
    @include mq-above(md) {
      font-size: 3.0rem;
    }
  }
  .cat-icon {
    $keisu-sp: .51;
    $keisu-pc: .84;
    margin-right: 10px;
    fill: $key-color;
    @include mq-above(md) {
      margin-right: 20px;
    }
    &--list {
      width: 45.5px * $keisu-sp;
      height: 32.5px * $keisu-sp;
      @include mq-above(md) {
        width: 45.5px * $keisu-pc;
        height: 32.5px * $keisu-pc;
      }
    }
    &--develop {
      width: 28px * $keisu-sp;
      height: 44px * $keisu-sp;
      @include mq-above(md) {
        width: 28px * $keisu-pc;
        height: 44px * $keisu-pc;
      }
    }
    &--design {
      width: 44px * $keisu-sp;
      height: 38.5px * $keisu-sp;
      @include mq-above(md) {
        width: 44px * $keisu-pc;
        height: 38.5px * $keisu-pc;
      }
    }
    &--welfare {
      width: 44px * $keisu-sp;
      height: 43.5px * $keisu-sp;
      @include mq-above(md) {
        width: 44px * $keisu-pc;
        height: 43.5px * $keisu-pc;
      }
    }
    &--care {
      width: 44px * $keisu-sp;
      height: 38px * $keisu-sp;
      @include mq-above(md) {
        width: 44px * $keisu-pc;
        height: 38px * $keisu-pc;
      }
    }
    &--community {
      width: 44px * $keisu-sp;
      height: 46.5px * $keisu-sp;
      @include mq-above(md) {
        width: 44px * $keisu-pc;
        height: 46.5px * $keisu-pc;
      }
    }
    &--seminar {
      width: 31px * $keisu-sp;
      height: 49.5px * $keisu-sp;
      @include mq-above(md) {
        width: 31px * $keisu-pc;
        height: 49.5px * $keisu-pc;
      }
    }
    &--action {
      width: 53px * $keisu-sp;
      height: 44.5px * $keisu-sp;
      @include mq-above(md) {
        width: 53px * $keisu-pc;
        height: 44.5px * $keisu-pc;
      }
    }
    &--other {
      width: 49px * $keisu-sp;
      height: 45.5px * $keisu-sp;
      @include mq-above(md) {
        width: 49px * $keisu-pc;
        height: 45.5px * $keisu-pc;
      }
    }
  }
}
.blog-list-body {
  position: relative;
  padding: 30px 0 0 0;
  @include mq-above(md) {
    padding-top: 70px;
  }
}
.card-list {
  @include mq-above(sm) {
    display: flex;
    flex-wrap: wrap;
    margin: -25px;
  }
  &-item {
    &:nth-child(n+2) {
      padding-top: 25px;
    }
    @include mq-above(sm) {
      padding: 25px;
      width: 50%;
    }
    @include mq-above(md) {
      width: 33.33333333%;
    }
  }
}
.card-post {
  position: relative;
  &-category {
    position: absolute;
    top: 0;
    left: 0;
    padding: 6px 10px;
    background-color: $key-color;
    color: $text-color-white;
    text-decoration: none;
  }
  &-image {
    position: relative;
    overflow: hidden;
    height: calc(374/750 * 100vw);
    @include mq-above(sm) {
      height: calc(188/768 * 100vw);
    }
    @include mq-above(md) {
      height: calc(200/1200 * 100vw);
    }
    @include mq-above(lg) {
      height: 200px;
    }
    > img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      transform: translate(-50%, -50%);
    }
  }
  &-title {
    margin: 1em 0 0 0;
    font-size: calc(32/750 * 100vw);
    line-height: (29/16);
    @include mq-above(xxs) {
      font-size: 1.6rem;
    }
    @include mq-above(md) {
      // height: 3.8em;
      font-size: 1.8rem;
      line-height: (34/18);
    }
  }
  &-excerpt {
    margin: .75em 0 0 0;
    font-size: calc(30/750 * 100vw);
    line-height: (29/16);
    @include mq-above(xxs) {
      font-size: 1.5rem;
    }
    @include mq-above(md) {
      font-size: 1.6rem;
    }
  }
  &-date {
    margin: .75em 0 0 0;
    font-size: calc(30/750 * 100vw);
    line-height: (29/16);
    @include mq-above(xxs) {
      font-size: 1.5rem;
    }
    @include mq-above(md) {
      font-size: 1.6rem;
    }
  }
  &-tags {
    margin: .75em 0 0 0;
    font-size: calc(30/750 * 100vw);
    line-height: (29/16);
    @include mq-above(xxs) {
      font-size: 1.5rem;
    }
    @include mq-above(md) {
      font-size: 1.6rem;
    }
    > a {
      margin: 0 .1em;
    }
  }
  .read-more {
    display: inline-block;
  }
}
/* blog post */
.post-title {
  margin: 0;
  @include mq-above(md) {
    margin-top: 15px;
    font-size: 3.0rem;
    font-weight: bold;
    line-height: (50/30);
  }
}
.post-date {
  margin: 0;
  @include mq-above(md) {
    font-size: 1.6rem;
    line-height: (26/16);
  }
}
.post-body {
  @include mq-above(md) {
    margin-top: 50px;
  }
  p,
  h3 {
    margin: 1em 0;
    font-size: calc(32/750 * 100vw);
    line-height: (29/16);
    @include mq-above(xxs) {
      font-size: 1.6rem;
    }
    @include mq-above(md) {
      font-size: 1.8rem;
      line-height: (30/18);
    }
  }
  p {
    margin: 1em 0;
  }
  h3 {
    font-weight: bold;
  }
  h4 {
    margin: 1em 0;
    font-size: calc(28/750 * 100vw);
    font-weight: bold;
    line-height: (25/14);
    @include mq-above(xxs) {
      font-size: 1.4rem;
    }
    @include mq-above(md) {
      font-size: 1.6rem;
      line-height: (26/16);
    }
  }
  strong,
  em {
    font-weight: bold;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}
/* for 404 error-container */
.error404-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  width: 100%;
  height: 100%;
  > p {
    text-align: center;
  }
}
.error404-title {
  margin: 0;
}
.error404-text {
  margin: 0;
}
