// MEDIA QUERIES
// breakpoints
$breakpoints: (
  xxs: 375px,
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px
) !default;
// RESPOND ABOVE
@mixin mq-above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @if $breakpoint-value >= map-get($breakpoints, sm) {
      @media print, screen and (min-width: $breakpoint-value) {
        @content;
      }
    }
    @else {
      @media screen and (min-width: $breakpoint-value) {
        @content;
      }
    }
  }
  @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
// RESPOND BELOW
@mixin mq-below($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @if $breakpoint-value <= map-get($breakpoints, sm) {
      @media screen and (max-width: ($breakpoint-value - 1)) {
        @content;
      }
    }
    @else {
      @media print, screen and (max-width: ($breakpoint-value - 1)) {
        @content;
      }
    }
  }
  @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
// RESPOND BETWEEN
@mixin mq-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    @if $lower-breakpoint >= map-get($breakpoints, sm) {//768以上
      @media print, screen and (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
        @content;
      }
    }
    @elseif $upper-breakpoint >= map-get($breakpoints, sm) {
      @media print, screen and (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
        @content;
      }
    }
    @else {
      @media screen and (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
        @content;
      }
    }
  }
  @else {
    @if (map-has-key($breakpoints, $lower) == false) {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    @if (map-has-key($breakpoints, $upper) == false) {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
// ZERO PADDING
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}
