@charset "UTF-8";
@import-normalize;
/**
 * Base Style
 */
@font-face {
  font-family: 'MyHiragino';
  src: local("HiraginoSans-W3"), local("ヒラギノ角ゴ ProN W3"), local("Hiragino Kaku Gothic ProN");
}

@font-face {
  font-family: 'MyHiragino';
  font-weight: bold;
  src: local("HiraginoSans-W6"), local("ヒラギノ角ゴ ProN W6"), local("Hiragino Kaku Gothic ProN W6");
}

@font-face {
  font-family: 'MyHiragino';
  font-weight: 800;
  src: local("HiraginoSans-W8"), local("ヒラギノ角ゴ StdN"), local("Hiragino Kaku Gothic StdN");
}

@font-face {
  font-family: 'MyYuGothic';
  src: local("Yu Gothic Medium");
}

@font-face {
  font-family: 'MyYuGothic';
  src: local("Yu Gothic Bold");
  font-weight: bold;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  min-height: 568px;
  color: #000000;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "メイリオ", Meiryo, sans-serif;
  font-size: calc(32/750 * 100vw);
  font-weight: 500;
  line-height: 1.8125;
  overflow-y: scroll;
}

@media screen and (min-width: 375px) {
  body {
    font-size: 1.6rem;
  }
}

@media print, screen and (min-width: 992px) {
  body {
    font-size: 1.8rem;
    line-height: 1.66667;
  }
}

_:lang(x)::-ms-backdrop, body {
  font-family: "メイリオ", Meiryo, sans-serif;
}

.disabled-scroll {
  overflow: hidden;
}

a {
  color: #008ae6;
}

a:hover {
  opacity: .8;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

_:-ms-lang(x)::-ms-backdrop[src$=".svg"], _:-ms-lang(x)::-ms-backdrop[src$=".svgz"], img[src$=".svg"], img[src$=".svgz"] {
  width: 100%;
}

.visible-xxs {
  display: block !important;
}

@media screen and (min-width: 375px) {
  .visible-xxs {
    display: none !important;
  }
}

.visible-xs {
  display: block !important;
}

@media screen and (min-width: 576px) {
  .visible-xs {
    display: none !important;
  }
}

.visible-sp {
  display: block !important;
}

@media print, screen and (min-width: 768px) {
  .visible-sp {
    display: none !important;
  }
}

.visible-tablet {
  display: block !important;
}

@media print, screen and (min-width: 992px) {
  .visible-tablet {
    display: none !important;
  }
}

.visible-pc {
  display: none !important;
}

@media print, screen and (min-width: 992px) {
  .visible-pc {
    display: block !important;
  }
}

.inner {
  padding: 0 calc(60/750 * 100vw);
}

@media screen and (min-width: 375px) {
  .inner {
    padding: 0 30px;
  }
}

@media print, screen and (min-width: 992px) {
  .inner {
    margin: 0 auto;
    padding: 0 40px;
  }
}

@media print, screen and (min-width: 1200px) {
  .inner {
    max-width: 1200px;
  }
}

@media print, screen and (min-width: 992px) {
  .inner--narrow {
    padding: 0;
    width: calc(950 / 1200 * 100vw);
  }
}

@media print, screen and (min-width: 1200px) {
  .inner--narrow {
    width: 950px;
  }
}

/**
 * Layout Style
 */
.l-wrapper {
  position: relative;
  top: 0;
  width: 100%;
  min-height: 100%;
  transition: top 1s;
}

.l-wrapper.scrollready {
  top: 20%;
  transition: none;
}

.l-wrapper.scrollup {
  top: 0;
  transition: top 1s;
}

.l-header {
  position: fixed;
  width: 100%;
  height: 72px;
  z-index: 101;
  background-color: #ffffff;
}

@media print, screen and (min-width: 992px) {
  .l-header {
    height: auto;
  }
}

.l-header > .inner {
  padding-left: 15px;
  padding-right: 15px;
}

@media print, screen and (min-width: 992px) {
  .l-header > .inner {
    padding-left: 0;
    padding-right: 0;
  }
}

.l-gnav {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
}

.l-gnav.is-open {
  display: flex;
  visibility: visible;
}

@media print, screen and (max-width: 991px) {
  .l-gnav {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: #ffffff;
    overflow-y: auto;
    visibility: hidden;
  }
}

@media print, screen and (min-width: 992px) {
  .l-gnav {
    position: static;
    display: block;
    width: auto;
  }
}

.l-main {
  position: relative;
  padding-top: 72px;
  padding-bottom: 157px;
  width: 100%;
}

@media print, screen and (min-width: 768px) {
  .l-main {
    padding-bottom: 134px;
  }
}

@media print, screen and (min-width: 992px) {
  .l-main {
    padding-top: 93px;
    padding-bottom: 195px;
  }
}

.home .l-main {
  padding-bottom: 371px;
}

@media print, screen and (min-width: 768px) {
  .home .l-main {
    padding-bottom: 290px;
  }
}

@media print, screen and (min-width: 992px) {
  .home .l-main {
    padding-bottom: 434px;
  }
}

.l-top-intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #008ae6;
  z-index: 9999;
  transition: top 1s;
}

.l-top-intro.scrollup {
  top: -100%;
}

.l-top-slide {
  position: relative;
  overflow: hidden;
}

.l-top-action {
  margin-top: 50px;
}

@media print, screen and (min-width: 992px) {
  .l-top-action {
    margin-top: 100px;
  }
}

.l-top-about {
  position: relative;
  margin-top: 50px;
}

@media print, screen and (min-width: 992px) {
  .l-top-about {
    margin-top: 180px;
  }
}

.l-top-about-inner {
  background-color: #008ae6;
}

.l-top-other-business {
  margin-top: 50px;
  margin-bottom: 50px;
}

@media print, screen and (min-width: 992px) {
  .l-top-other-business {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

@media print, screen and (min-width: 992px) {
  .l-other-business {
    display: flex;
    justify-content: space-between;
    margin: 60px -25px 0;
  }
}

@media print, screen and (min-width: 992px) {
  .l-other-business-col {
    padding: 0 25px;
    width: 560px;
  }
}

.l-other-business-col:nth-child(n+2) {
  margin-top: 24px;
}

@media print, screen and (min-width: 992px) {
  .l-other-business-col:nth-child(n+2) {
    margin-top: 0;
  }
}

@media print, screen and (min-width: 992px) {
  .l-page-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

@media print, screen and (min-width: 992px) {
  .l-page-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.l-page-aside {
  margin-top: 55px;
}

@media print, screen and (min-width: 992px) {
  .l-page-aside {
    margin-top: 0;
    margin-right: 80px;
    width: 244px;
  }
}

.l-footer {
  position: relative;
  margin-top: -107px;
  padding-bottom: 30px;
  height: 107px;
  background-color: #008ae6;
}

@media print, screen and (min-width: 768px) {
  .l-footer {
    margin-top: -84px;
    height: 84px;
  }
}

@media print, screen and (min-width: 992px) {
  .l-footer {
    margin-top: -120px;
    padding-bottom: 60px;
    height: 120px;
  }
}

.home .l-footer {
  margin-top: -321px;
  height: 321px;
}

@media print, screen and (min-width: 768px) {
  .home .l-footer {
    margin-top: -240px;
    height: 240px;
  }
}

@media print, screen and (min-width: 992px) {
  .home .l-footer {
    margin-top: -359px;
    height: 359px;
  }
}

.l-footer:before {
  content: "";
  position: absolute;
  top: -54px;
  width: 100%;
  height: 54px;
  background-image: url(../img/shared/bg_footer_sp.svg);
  background-repeat: no-repeat;
  background-size: 100% 100px;
  z-index: 99;
}

@media print, screen and (min-width: 992px) {
  .l-footer:before {
    top: -75px;
    height: 76px;
    background-image: url(../img/shared/bg_footer.svg);
    background-size: 100% 187px;
  }
}

.l-page-top {
  position: fixed;
  right: 0;
  bottom: 100px;
  z-index: 100;
}

/**
 * Header Style
 */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
}

@media print, screen and (min-width: 992px) {
  .header {
    margin: 0 auto;
    padding: 15px;
  }
}

@media print, screen and (min-width: 1200px) {
  .header {
    width: 1200px;
  }
}

.logo {
  width: 101px;
  height: 32px;
  z-index: 102;
}

@media print, screen and (min-width: 992px) {
  .logo {
    width: 202px;
    height: 63px;
  }
}

.menu-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  width: 26px;
  height: 22px;
  cursor: pointer;
  z-index: 102;
}

@media print, screen and (min-width: 992px) {
  .menu-button {
    display: none;
  }
}

.menu-button:before, .menu-button:after {
  content: "";
}

.menu-button > span, .menu-button:before, .menu-button:after {
  display: block;
  border-top: 2.5px solid #008ae6;
}

.menu-button.is-open > span {
  opacity: 0;
}

.menu-button.is-open:before {
  transform: translate(0, 10px) rotate(45deg);
}

.menu-button.is-open:after {
  transform: translate(0, -10px) rotate(-45deg);
}

/**
 * Footer Style
 */
.footer {
  position: relative;
}

.bg-dolphin {
  position: absolute;
  left: 50%;
  top: -70px;
  transform: translate(-140px, 0);
  display: block;
  width: 38px;
  height: 28px;
  background-image: url(../img/shared/bg_dolphin.png);
  background-repeat: no-repeat;
  background-size: cover;
}

@media print, screen and (min-width: 992px) {
  .bg-dolphin {
    left: 50%;
    top: -110px;
    transform: translate(-520px, 0);
    width: 76px;
    height: 56px;
  }
}

.footer-contact {
  margin-bottom: 30px;
}

@media print, screen and (min-width: 992px) {
  .footer-contact {
    margin-bottom: 60px;
  }
}

.footer-contact-text {
  margin-top: 0;
  color: #ffffff;
  text-align: center;
}

@media print, screen and (min-width: 992px) {
  .footer-contact-text {
    font-size: 2.6rem;
    line-height: 1.53846;
  }
}

.footer-contact-button {
  display: flex;
  justify-content: center;
}

.footer-link-list {
  list-style: none;
  margin: 0 -20px;
  padding-left: 0;
  display: flex;
  justify-content: center;
  position: relative;
}

.footer-link-list > li {
  padding: 0 20px;
  font-size: 1.4rem;
  line-height: 1.3;
}

@media print, screen and (min-width: 992px) {
  .footer-link-list > li {
    font-size: 1.6rem;
    line-height: 1.15;
  }
}

.footer-link-list > li:nth-child(n+2) {
  border-left: 1px solid #fff;
}

.footer-link {
  color: #ffffff;
  text-decoration: none;
}

.copyright {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  color: #ffffff;
  font-size: 1.3rem;
  text-align: center;
}

@media print, screen and (min-width: 992px) {
  .copyright {
    font-size: 1.6rem;
  }
}

.page-top {
  display: block;
  border: 1px solid #ffffff;
  border-radius: 50% 0 0 50%;
  border-width: 1px 0 1px 1px;
  width: 55px;
  height: 50px;
  background-color: #008ae6;
  color: #ffffff;
  font-size: 1.4rem;
  line-height: 1;
  text-decoration: none;
}

@media print, screen and (min-width: 992px) {
  .page-top {
    width: 69px;
    height: 64px;
    font-size: 1.7rem;
  }
}

.page-top-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 45px;
  height: 100%;
}

@media print, screen and (min-width: 992px) {
  .page-top-label {
    width: 60px;
  }
}

.page-top-label > img {
  width: 25px;
}

@media print, screen and (min-width: 992px) {
  .page-top-label > img {
    width: auto;
  }
}

.page-top-label > span {
  margin-top: 6px;
}

/**
 * Nav Style
 */
.gnav-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.gnav-list > li {
  margin: calc(30/736*100vh) 0;
  text-align: center;
}

@media print, screen and (min-width: 992px) {
  .gnav-list {
    display: flex;
  }
  .gnav-list > li {
    margin: 0 9px;
  }
  .gnav-list > li:first-child {
    display: none;
  }
}

@media print, screen and (min-width: 1200px) {
  .gnav-list > li {
    margin: 0 18px;
  }
}

.gnav-label {
  padding: 10px 8px;
  border-bottom: 2px solid transparent;
  color: #000;
  font-size: 1.8rem;
  text-decoration: none;
}

.gnav-label.is-touch {
  color: #008ae6;
  border-bottom-color: #008ae6;
}

@media print, screen and (min-width: 992px) {
  .gnav-label {
    border-bottom-width: 4px;
    padding: 10px 8px;
    font-size: 1.6rem;
  }
  .gnav-label.is-current, .gnav-label:hover {
    color: #008ae6;
    border-bottom-color: #008ae6;
  }
}

/* category-list */
.category-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: -7px;
  padding-left: 0;
}

@media print, screen and (min-width: 992px) {
  .category-list {
    margin: -10px;
  }
}

.category-list > li {
  padding: 7px;
  width: 50%;
}

@media print, screen and (min-width: 768px) {
  .category-list > li {
    padding: 10px;
    width: 33.333333333%;
  }
}

@media print, screen and (min-width: 992px) {
  .category-list > li {
    padding: 10px;
    width: 20%;
  }
}

@media print, screen and (min-width: 992px) {
  .category-list--post {
    flex-wrap: nowrap;
    flex-direction: column;
  }
}

.category-list--post > li {
  width: 100%;
}

@media screen and (min-width: 576px) {
  .category-list--post > li {
    width: 50%;
  }
}

@media print, screen and (min-width: 768px) {
  .category-list--post > li {
    width: 33.333333333%;
  }
}

@media print, screen and (min-width: 992px) {
  .category-list--post > li {
    width: 100%;
  }
}

.category-label {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #008ae6;
  border-radius: 20px;
  height: 40px;
  background-color: #ffffff;
  color: #008ae6;
  text-decoration: none;
}

@media print, screen and (min-width: 992px) {
  .category-label {
    border-radius: 27px;
    height: 54px;
    font-size: 2.0rem;
  }
  .category-label:hover {
    background-color: #008ae6;
    color: #ffffff;
    opacity: 1;
  }
}

.current .category-label {
  background-color: #008ae6;
  color: #ffffff;
}

.category-label .category-icon {
  margin-right: 7px;
  fill: #008ae6;
}

@media print, screen and (min-width: 992px) {
  .category-label .category-icon {
    margin-right: 9px;
  }
}

.category-label .category-icon--list {
  width: 23px;
  height: 16.5px;
}

@media print, screen and (min-width: 992px) {
  .category-label .category-icon--list {
    width: 31px;
    height: 22px;
  }
}

.category-label .category-icon--develop {
  width: 15px;
  height: 23px;
}

@media print, screen and (min-width: 992px) {
  .category-label .category-icon--develop {
    width: 19px;
    height: 30px;
  }
}

.category-label .category-icon--design {
  width: 22px;
  height: 20px;
}

@media print, screen and (min-width: 992px) {
  .category-label .category-icon--design {
    width: 30px;
    height: 26px;
  }
}

.category-label .category-icon--welfare {
  width: 22px;
  height: 23.5px;
}

@media print, screen and (min-width: 992px) {
  .category-label .category-icon--welfare {
    width: 30px;
    height: 29px;
  }
}

.category-label .category-icon--care {
  width: 22px;
  height: 19.5px;
}

@media print, screen and (min-width: 992px) {
  .category-label .category-icon--care {
    width: 30px;
    height: 26px;
  }
}

.category-label .category-icon--community {
  width: 22.5px;
  height: 24px;
}

@media print, screen and (min-width: 992px) {
  .category-label .category-icon--community {
    width: 30px;
    height: 31px;
  }
}

.category-label .category-icon--seminar {
  width: 15.5px;
  height: 25px;
}

@media print, screen and (min-width: 992px) {
  .category-label .category-icon--seminar {
    width: 22px;
    height: 34px;
  }
}

.category-label .category-icon--action {
  width: 27px;
  height: 23px;
}

@media print, screen and (min-width: 992px) {
  .category-label .category-icon--action {
    width: 36px;
    height: 30px;
  }
}

.category-label .category-icon--other {
  width: 24.5px;
  height: 23px;
}

@media print, screen and (min-width: 992px) {
  .category-label .category-icon--other {
    width: 33px;
    height: 30px;
  }
}

.current .category-label .category-icon {
  fill: #ffffff;
}

@media print, screen and (min-width: 992px) {
  .category-label:hover .category-icon {
    fill: #ffffff;
  }
}

.tag-list {
  list-style: none;
  margin: 20px -.5em -.5em;
  padding-left: 0;
}

.tag-list > li {
  display: inline-block;
  padding: .5em;
}

/* pagination */
.navigation.pagination {
  position: relative;
}

.screen-reader-text {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

.nav-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0 0;
}

.nav-links .page-numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  padding: 0 10px;
  border: 1px solid #505050;
  height: 35px;
  color: #505050;
  text-decoration: none;
}

.nav-links .page-numbers.current {
  border-color: #008ae6;
  color: #008ae6;
}

.nav-links .page-numbers.prev, .nav-links .page-numbers.next {
  position: relative;
}

@media print, screen and (min-width: 992px) {
  .nav-links .page-numbers.prev, .nav-links .page-numbers.next {
    font-size: 2.6rem;
    width: 200px;
    height: 69px;
  }
}

.nav-links .page-numbers.prev:before, .nav-links .page-numbers.next:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
  margin: auto 0;
  width: 6px;
  height: 6px;
}

@media print, screen and (min-width: 992px) {
  .nav-links .page-numbers.prev:before, .nav-links .page-numbers.next:before {
    width: 12px;
    height: 12px;
  }
}

.nav-links .page-numbers.prev {
  padding-left: 15px;
}

@media print, screen and (min-width: 992px) {
  .nav-links .page-numbers.prev {
    padding-left: 20px;
  }
}

.nav-links .page-numbers.prev:before {
  left: 5px;
  border-top: 1px solid #505050;
  border-left: 1px solid #505050;
  transform: rotate(-45deg);
}

@media print, screen and (min-width: 992px) {
  .nav-links .page-numbers.prev:before {
    left: 10px;
    border-width: 3px;
  }
}

.nav-links .page-numbers.next {
  padding-right: 15px;
}

@media print, screen and (min-width: 992px) {
  .nav-links .page-numbers.next {
    padding-right: 20px;
  }
}

.nav-links .page-numbers.next:before {
  right: 5px;
  border-bottom: 1px solid #505050;
  border-right: 1px solid #505050;
  transform: rotate(-45deg);
}

@media print, screen and (min-width: 992px) {
  .nav-links .page-numbers.next:before {
    right: 10px;
    border-width: 3px;
  }
}

@media print, screen and (min-width: 992px) {
  .nav-links .page-numbers {
    margin: 0 10px;
    border-width: 3px;
    width: 55px;
    height: 55px;
  }
  .nav-links .page-numbers:hover {
    border-color: #008ae6;
    color: #008ae6;
    opacity: 1;
  }
  .nav-links .page-numbers:hover.prev:before, .nav-links .page-numbers:hover.next:before {
    border-color: #008ae6;
  }
}

.nav-links .dots {
  border: none;
}

@media print, screen and (min-width: 992px) {
  .nav-links .dots:hover {
    color: #505050;
  }
}

.nav-links .src-text {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

@media print, screen and (min-width: 992px) {
  .nav-links .prev .src-text,
  .nav-links .next .src-text {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
  }
}

.post-pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px 0 0 0;
}

@media print, screen and (min-width: 992px) {
  .post-pagination {
    justify-content: center;
  }
}

.post-pagination .prev-post,
.post-pagination .next-post {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #505050;
  width: calc(50% - 5px);
  height: 52px;
  color: #505050;
  text-decoration: none;
}

@media print, screen and (min-width: 992px) {
  .post-pagination .prev-post,
  .post-pagination .next-post {
    border-width: 3px;
    font-size: 2.6rem;
    width: 280px;
    height: 75px;
  }
  .post-pagination .prev-post:hover,
  .post-pagination .next-post:hover {
    border-color: #008ae6;
    color: #008ae6;
    opacity: 1;
  }
  .post-pagination .prev-post:hover:before,
  .post-pagination .next-post:hover:before {
    border-color: #008ae6;
  }
}

.post-pagination .prev-post:before, .post-pagination .prev-post:after,
.post-pagination .next-post:before,
.post-pagination .next-post:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
  margin: auto 0;
  width: 6px;
  height: 6px;
}

@media print, screen and (min-width: 992px) {
  .post-pagination .prev-post:before, .post-pagination .prev-post:after,
  .post-pagination .next-post:before,
  .post-pagination .next-post:after {
    width: 12px;
    height: 12px;
  }
}

.post-pagination .prev-post {
  margin-right: auto;
}

@media print, screen and (min-width: 992px) {
  .post-pagination .prev-post {
    margin-right: 5px;
  }
}

.post-pagination .prev-post:before {
  left: 8px;
  border-top: 1px solid #505050;
  border-left: 1px solid #505050;
  transform: rotate(-45deg);
}

@media screen and (min-width: 375px) {
  .post-pagination .prev-post:before {
    left: 10px;
  }
}

@media print, screen and (min-width: 992px) {
  .post-pagination .prev-post:before {
    left: 15px;
    border-width: 3px;
  }
}

.post-pagination .next-post {
  margin-left: auto;
}

@media print, screen and (min-width: 992px) {
  .post-pagination .next-post {
    margin-left: 5px;
  }
}

.post-pagination .next-post:before {
  right: 8px;
  border-bottom: 1px solid #505050;
  border-right: 1px solid #505050;
  transform: rotate(-45deg);
}

@media screen and (min-width: 375px) {
  .post-pagination .next-post:before {
    right: 10px;
  }
}

@media print, screen and (min-width: 992px) {
  .post-pagination .next-post:before {
    right: 15px;
    border-width: 3px;
  }
}

/**
 * Button Style
 */
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #008ae6;
  width: 100%;
  height: 52px;
  background: #ffffff;
  color: #008ae6;
  font-size: calc(36/750 * 100vw);
  line-height: 1;
  text-decoration: none;
  text-align: center;
}

@media screen and (min-width: 375px) {
  .button {
    font-size: 1.8rem;
  }
}

@media print, screen and (min-width: 992px) {
  .button {
    margin: 0 20px;
    border-width: 3px;
    width: 330px;
    height: 75px;
    font-size: 2.6rem;
  }
}

.button--contact {
  background-color: #008ae6;
  color: #ffffff;
  border-color: #ffffff;
}

.button.is-disabled {
  pointer-events: none;
}

.input-submit {
  appearance: none;
  padding: 0;
  border: 2px solid #505050;
  border-radius: 0;
  width: 100%;
  height: 52px;
  background: transparent;
  font-size: calc(36/750 * 100vw);
  line-height: 1;
  text-align: center;
  outline: none;
}

.input-submit[name="submit"], .input-submit[name="submitConfirm"] {
  border-color: #008ae6;
  color: #008ae6;
}

@media screen and (min-width: 375px) {
  .input-submit {
    font-size: 1.8rem;
  }
}

@media print, screen and (min-width: 992px) {
  .input-submit {
    margin: 0 20px;
    border-width: 3px;
    width: 330px;
    height: 75px;
    font-size: 2.6rem;
  }
}

@media print, screen and (max-width: 991px) {
  .input-submit--back {
    margin-bottom: 20px;
  }
}

/**
 * Page Style
 */
/* common */
.text {
  margin: 1em 0;
}

.align-center {
  text-align: center;
}

.list {
  list-style: disc;
  margin: 0 0 0 1.25em;
  padding: 0;
}

.list--none {
  list-style: none;
  margin-left: 0;
}

/* page-header */
.page-header {
  position: relative;
  height: 98px;
  background-image: url(../img/shared/bg_page_header_sp.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  overflow: hidden;
}

@media print, screen and (min-width: 992px) {
  .page-header {
    background-image: url(../img/shared/bg_page_header.svg);
    background-size: 100% 100%;
    background-position: center center;
    height: 196px;
  }
}

.page-header .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
}

.page-header .inner:before {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
}

.page-blog .page-header .inner:before,
.single-post .page-header .inner:before,
.category .page-header .inner:before,
.tag .page-header .inner:before {
  bottom: 20px;
  left: 50%;
  transform: translate(-136%, 0);
  width: 176px;
  height: 71px;
  background-image: url(../img/blog/bg_jinbeizame.svg);
  background-size: cover;
}

@media print, screen and (min-width: 992px) {
  .page-blog .page-header .inner:before,
  .single-post .page-header .inner:before,
  .category .page-header .inner:before,
  .tag .page-header .inner:before {
    bottom: 20px;
    transform: translate(-140%, 0);
    width: 352px;
    height: 142px;
  }
}

.parent-contact .page-header .inner:before {
  top: -5px;
  left: 50%;
  transform: translate(60px, 0);
  width: 121.5px;
  height: 78px;
  background-image: url(../img/contact/bg_fish.svg);
  background-size: cover;
}

@media print, screen and (min-width: 992px) {
  .parent-contact .page-header .inner:before {
    top: -10px;
    transform: translate(180px, 0);
    width: 243px;
    height: 156px;
  }
}

.page-title {
  position: relative;
  margin: -6px 0 0 0;
  color: #ffffff;
  font-size: calc(40/750 * 100vw);
  line-height: 1.2;
  text-align: center;
}

@media screen and (min-width: 375px) {
  .page-title {
    font-size: 2.0rem;
  }
}

@media print, screen and (min-width: 992px) {
  .page-title {
    margin-top: -12px;
    font-size: 4.0rem;
  }
}

/* page-content */
.page-content {
  padding-top: 48px;
  padding-bottom: 60px;
}

@media print, screen and (min-width: 992px) {
  .page-content {
    padding-top: 56px;
    padding-bottom: 120px;
  }
}

/* section */
.section:nth-child(n+2) {
  margin-top: 20px;
}

@media print, screen and (min-width: 992px) {
  .section:nth-child(n+2) {
    margin-top: 40px;
  }
}

/* title */
.primary-title {
  margin: 0;
}

.secondary-title {
  margin: 1em 0;
}

/* form */
.contact-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 23px 0;
  font-size: calc(32/750 * 100vw);
  font-weight: bold;
  line-height: 1.7;
  text-align: center;
}

@media screen and (min-width: 375px) {
  .contact-text {
    font-size: 1.6rem;
  }
}

@media print, screen and (min-width: 992px) {
  .contact-text {
    margin: 0 0 30px 0;
    height: 60px;
    font-size: 2.0rem;
  }
}

.contact-text--thanks {
  color: #008ae6;
  font-size: calc(40/750 * 100vw);
  line-height: 1.76923;
}

@media print, screen and (min-width: 992px) {
  .contact-text--thanks {
    font-size: 2.6rem;
  }
}

.contact-step {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-step > li {
  position: relative;
  width: 120px;
  height: 120px;
}

@media print, screen and (min-width: 992px) {
  .contact-step > li {
    width: 150px;
    height: 150px;
  }
}

.contact-step > li.is-current {
  width: 150px;
  width: 150px;
}

@media print, screen and (min-width: 992px) {
  .contact-step > li.is-current {
    width: 180px;
    width: 180px;
  }
}

.step-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  z-index: 1;
}

@media print, screen and (min-width: 992px) {
  .step-label {
    font-size: 2.2rem;
    line-height: 1.09091;
  }
}

.decagon:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  margin: auto;
  width: 70px;
  height: 70px;
  background-image: url(../img/shared/bg_decagon.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

@media print, screen and (min-width: 992px) {
  .decagon:before {
    width: 103px;
    height: 103px;
  }
}

.decagon.is-current:before {
  width: 91px;
  height: 91px;
  background-image: url(../img/shared/bg_decagon_blue.svg);
}

@media print, screen and (min-width: 992px) {
  .decagon.is-current:before {
    width: 133px;
    height: 133px;
  }
}

.contact-body {
  margin: 43px 0 0 0;
}

@media print, screen and (min-width: 992px) {
  .contact-body {
    margin: 80px 0 0 0;
  }
}

.f-item {
  margin-top: 2px;
}

@media print, screen and (min-width: 992px) {
  .f-item {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
  }
}

.f-key {
  display: flex;
  padding: calc(30/750 * 100vw);
  padding: 15px;
  background-color: rgba(0, 138, 230, 0.25);
}

@media print, screen and (min-width: 992px) {
  .f-key {
    padding: 20px 30px;
    width: 286px;
  }
}

.f-label {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: calc(34/750 * 100vw);
  font-weight: bold;
  line-height: 1.41176;
}

@media screen and (min-width: 375px) {
  .f-label {
    font-size: 1.7rem;
  }
}

@media print, screen and (min-width: 992px) {
  .f-label {
    justify-content: space-between;
    font-size: 2.0rem;
  }
}

.f-label > .required {
  margin-left: 10px;
  padding: 3px;
  border-radius: 4px;
  background-color: #da1d1b;
  color: #ffffff;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: normal;
}

@media print, screen and (min-width: 992px) {
  .f-label > .required {
    margin-left: 0;
  }
}

.f-value {
  margin-top: 2px;
  padding: 15px;
  background-color: rgba(0, 138, 230, 0.1);
}

@media print, screen and (min-width: 992px) {
  .f-value {
    flex: 1 0 auto;
    margin: 0 0 0 4px;
    padding: 20px;
  }
}

.input-text {
  appearance: none;
  padding: 15px 12px;
  border: 2px solid #505050;
  border-radius: 0;
  width: 100%;
  background-color: #ffffff;
  font-size: 1.6rem;
  outline: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media print, screen and (min-width: 992px) {
  .input-text {
    padding: 14px 20px;
    font-size: 1.8rem;
  }
}

.input-text:focus {
  background-color: #fffff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.input-text::-webkit-input-placeholder {
  padding-top: .15em;
  line-height: 1;
}

.input-textarea {
  appearance: none;
  resize: none;
  display: block;
  padding: 15px 12px;
  border: 2px solid #505050;
  border-radius: 0;
  width: 100%;
  height: 10em;
  background-color: #ffffff;
  font-size: 1.6rem;
  outline: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media print, screen and (min-width: 992px) {
  .input-textarea {
    padding: 14px 20px;
    height: 16em;
    font-size: 1.8rem;
  }
}

.input-textarea:focus {
  background-color: #fffff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.mw_wp_form .f-value .vertical-item + .vertical-item {
  margin-top: 15px;
}

.input-radio {
  display: none;
}

.input-radio + label {
  position: relative;
  display: flex;
  align-items: center;
}

.input-radio:checked + label > .input-radio-disc:before {
  background-color: #008ae6;
  transform: translate(-50%, -50%) scale(1);
}

.input-radio-disc {
  position: relative;
  margin-right: calc(28/750 * 100vw);
  border: 2px solid #505050;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  pointer-events: none;
  user-select: none;
}

@media screen and (min-width: 375px) {
  .input-radio-disc {
    margin-right: 14px;
  }
}

@media print, screen and (min-width: 992px) {
  .input-radio-disc {
    border-width: 2px solid #505050;
    width: 34px;
    height: 34px;
  }
}

.input-radio-disc:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  display: block;
  margin: auto;
  border-radius: 50%;
  width: 50%;
  height: 50%;
  transition: transform .2s ease-in-out;
}

.input-checkbox {
  display: none;
}

.input-checkbox + label {
  position: relative;
  display: flex;
  align-items: center;
}

.input-checkbox:checked + label > .input-checkbox-box:before {
  transform: translate(-50%, -50%) scale(1);
}

.input-checkbox-box {
  position: relative;
  display: block;
  margin-right: calc(18/750 * 100vw);
  border: 2px solid #505050;
  width: 36px;
  height: 36px;
}

@media screen and (min-width: 375px) {
  .input-checkbox-box {
    margin-right: 9px;
  }
}

@media print, screen and (min-width: 992px) {
  .input-checkbox-box {
    margin-right: 20px;
    width: 40px;
    height: 40px;
  }
}

.input-checkbox-box:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  display: block;
  margin: auto;
  width: 20px;
  height: 18px;
  background-image: url(../img/shared/icon_check.svg);
  background-repeat: no-repeat;
  background-size: 20px 18px;
  transition: transform .2s ease-in-out;
}

.input-checkbox-box + .mwform-checkbox-field-text {
  flex: 1 0 auto;
  line-height: 1.5;
}

.privacy-check {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 0 0;
  width: 100%;
}

@media print, screen and (min-width: 992px) {
  .privacy-check {
    margin: 60px 0 0 0;
  }
}

.privacy-check > p {
  margin: 0;
}

.f-button-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px 0 0 0;
}

@media print, screen and (min-width: 992px) {
  .f-button-group {
    flex-direction: row;
    margin: 60px 0 0 0;
  }
}

/* blog */
.blog-list {
  padding-top: 30px;
}

@media print, screen and (min-width: 992px) {
  .blog-list {
    padding-top: 70px;
  }
}

.blog-list-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: calc(38/750 * 100vw);
}

@media screen and (min-width: 375px) {
  .blog-list-title {
    font-size: 1.9rem;
  }
}

@media print, screen and (min-width: 992px) {
  .blog-list-title {
    font-size: 3.0rem;
  }
}

.blog-list .cat-icon {
  margin-right: 10px;
  fill: #008ae6;
}

@media print, screen and (min-width: 992px) {
  .blog-list .cat-icon {
    margin-right: 20px;
  }
}

.blog-list .cat-icon--list {
  width: 23.205px;
  height: 16.575px;
}

@media print, screen and (min-width: 992px) {
  .blog-list .cat-icon--list {
    width: 38.22px;
    height: 27.3px;
  }
}

.blog-list .cat-icon--develop {
  width: 14.28px;
  height: 22.44px;
}

@media print, screen and (min-width: 992px) {
  .blog-list .cat-icon--develop {
    width: 23.52px;
    height: 36.96px;
  }
}

.blog-list .cat-icon--design {
  width: 22.44px;
  height: 19.635px;
}

@media print, screen and (min-width: 992px) {
  .blog-list .cat-icon--design {
    width: 36.96px;
    height: 32.34px;
  }
}

.blog-list .cat-icon--welfare {
  width: 22.44px;
  height: 22.185px;
}

@media print, screen and (min-width: 992px) {
  .blog-list .cat-icon--welfare {
    width: 36.96px;
    height: 36.54px;
  }
}

.blog-list .cat-icon--care {
  width: 22.44px;
  height: 19.38px;
}

@media print, screen and (min-width: 992px) {
  .blog-list .cat-icon--care {
    width: 36.96px;
    height: 31.92px;
  }
}

.blog-list .cat-icon--community {
  width: 22.44px;
  height: 23.715px;
}

@media print, screen and (min-width: 992px) {
  .blog-list .cat-icon--community {
    width: 36.96px;
    height: 39.06px;
  }
}

.blog-list .cat-icon--seminar {
  width: 15.81px;
  height: 25.245px;
}

@media print, screen and (min-width: 992px) {
  .blog-list .cat-icon--seminar {
    width: 26.04px;
    height: 41.58px;
  }
}

.blog-list .cat-icon--action {
  width: 27.03px;
  height: 22.695px;
}

@media print, screen and (min-width: 992px) {
  .blog-list .cat-icon--action {
    width: 44.52px;
    height: 37.38px;
  }
}

.blog-list .cat-icon--other {
  width: 24.99px;
  height: 23.205px;
}

@media print, screen and (min-width: 992px) {
  .blog-list .cat-icon--other {
    width: 41.16px;
    height: 38.22px;
  }
}

.blog-list-body {
  position: relative;
  padding: 30px 0 0 0;
}

@media print, screen and (min-width: 992px) {
  .blog-list-body {
    padding-top: 70px;
  }
}

@media print, screen and (min-width: 768px) {
  .card-list {
    display: flex;
    flex-wrap: wrap;
    margin: -25px;
  }
}

.card-list-item:nth-child(n+2) {
  padding-top: 25px;
}

@media print, screen and (min-width: 768px) {
  .card-list-item {
    padding: 25px;
    width: 50%;
  }
}

@media print, screen and (min-width: 992px) {
  .card-list-item {
    width: 33.33333333%;
  }
}

.card-post {
  position: relative;
}

.card-post-category {
  position: absolute;
  top: 0;
  left: 0;
  padding: 6px 10px;
  background-color: #008ae6;
  color: #ffffff;
  text-decoration: none;
}

.card-post-image {
  position: relative;
  overflow: hidden;
  height: calc(374/750 * 100vw);
}

@media print, screen and (min-width: 768px) {
  .card-post-image {
    height: calc(188/768 * 100vw);
  }
}

@media print, screen and (min-width: 992px) {
  .card-post-image {
    height: calc(200/1200 * 100vw);
  }
}

@media print, screen and (min-width: 1200px) {
  .card-post-image {
    height: 200px;
  }
}

.card-post-image > img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
}

.card-post-title {
  margin: 1em 0 0 0;
  font-size: calc(32/750 * 100vw);
  line-height: 1.8125;
}

@media screen and (min-width: 375px) {
  .card-post-title {
    font-size: 1.6rem;
  }
}

@media print, screen and (min-width: 992px) {
  .card-post-title {
    font-size: 1.8rem;
    line-height: 1.88889;
  }
}

.card-post-excerpt {
  margin: .75em 0 0 0;
  font-size: calc(30/750 * 100vw);
  line-height: 1.8125;
}

@media screen and (min-width: 375px) {
  .card-post-excerpt {
    font-size: 1.5rem;
  }
}

@media print, screen and (min-width: 992px) {
  .card-post-excerpt {
    font-size: 1.6rem;
  }
}

.card-post-date {
  margin: .75em 0 0 0;
  font-size: calc(30/750 * 100vw);
  line-height: 1.8125;
}

@media screen and (min-width: 375px) {
  .card-post-date {
    font-size: 1.5rem;
  }
}

@media print, screen and (min-width: 992px) {
  .card-post-date {
    font-size: 1.6rem;
  }
}

.card-post-tags {
  margin: .75em 0 0 0;
  font-size: calc(30/750 * 100vw);
  line-height: 1.8125;
}

@media screen and (min-width: 375px) {
  .card-post-tags {
    font-size: 1.5rem;
  }
}

@media print, screen and (min-width: 992px) {
  .card-post-tags {
    font-size: 1.6rem;
  }
}

.card-post-tags > a {
  margin: 0 .1em;
}

.card-post .read-more {
  display: inline-block;
}

/* blog post */
.post-title {
  margin: 0;
}

@media print, screen and (min-width: 992px) {
  .post-title {
    margin-top: 15px;
    font-size: 3.0rem;
    font-weight: bold;
    line-height: 1.66667;
  }
}

.post-date {
  margin: 0;
}

@media print, screen and (min-width: 992px) {
  .post-date {
    font-size: 1.6rem;
    line-height: 1.625;
  }
}

@media print, screen and (min-width: 992px) {
  .post-body {
    margin-top: 50px;
  }
}

.post-body p,
.post-body h3 {
  margin: 1em 0;
  font-size: calc(32/750 * 100vw);
  line-height: 1.8125;
}

@media screen and (min-width: 375px) {
  .post-body p,
  .post-body h3 {
    font-size: 1.6rem;
  }
}

@media print, screen and (min-width: 992px) {
  .post-body p,
  .post-body h3 {
    font-size: 1.8rem;
    line-height: 1.66667;
  }
}

.post-body p {
  margin: 1em 0;
}

.post-body h3 {
  font-weight: bold;
}

.post-body h4 {
  margin: 1em 0;
  font-size: calc(28/750 * 100vw);
  font-weight: bold;
  line-height: 1.78571;
}

@media screen and (min-width: 375px) {
  .post-body h4 {
    font-size: 1.4rem;
  }
}

@media print, screen and (min-width: 992px) {
  .post-body h4 {
    font-size: 1.6rem;
    line-height: 1.625;
  }
}

.post-body strong,
.post-body em {
  font-weight: bold;
}

.post-body img {
  max-width: 100%;
  height: auto;
}

/* for 404 error-container */
.error404-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  width: 100%;
  height: 100%;
}

.error404-container > p {
  text-align: center;
}

.error404-title {
  margin: 0;
}

.error404-text {
  margin: 0;
}

/**
 * Top page Style
 */
/* top intro */
.line1-str {
  width: 28px;
}

.top-intro-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.top-intro {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -50px;
}

.top-intro:before {
  content: "";
  display: block;
  position: absolute;
  width: 157px;
  height: 61px;
  background-image: url(../img/top/bg_intro_wave01.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

@media screen and (max-width: 575px) and (orientation: portrait) {
  .top-intro:before {
    right: -66px;
    top: 190px;
  }
}

@media screen and (min-width: 576px), (orientation: landscape) {
  .top-intro:before {
    right: -140px;
    top: 140px;
  }
}

@media print, screen and (min-width: 992px) {
  .top-intro:before {
    right: -266px;
    top: 256px;
  }
}

.top-intro:after {
  content: "";
  display: block;
  position: absolute;
  width: 157px;
  height: 64px;
  background-image: url(../img/top/bg_intro_wave02.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

@media screen and (max-width: 575px) and (orientation: portrait) {
  .top-intro:after {
    left: -100px;
    top: 320px;
  }
}

@media screen and (min-width: 576px), (orientation: landscape) {
  .top-intro:after {
    left: -140px;
    top: 250px;
  }
}

@media print, screen and (min-width: 992px) {
  .top-intro:after {
    left: -280px;
    top: 440px;
  }
}

.top-intro-logo {
  width: 94px;
}

@media print, screen and (min-width: 992px) {
  .top-intro-logo {
    width: 144px;
    height: 175.2px;
  }
}

.top-intro-slogan {
  color: #ffffff;
  font-size: 2.2rem;
  text-align: center;
}

@media print, screen and (min-width: 992px) {
  .top-intro-slogan {
    font-size: 2.8rem;
  }
}

.top-intro-slogan {
  margin: 0;
}

.top-intro-slogan > span {
  position: relative;
  display: block;
  margin: 1em 0;
  height: 100%;
}

.top-intro-text {
  margin: 0;
}

.top-intro-text > span {
  position: relative;
  display: block;
  margin: 0;
  height: 100%;
  color: #ffffff;
  font-size: 1.5rem;
  text-align: center;
  overflow: hidden;
}

@media screen and (max-width: 575px) and (orientation: portrait) {
  .top-intro-text > span > span {
    position: relative;
    display: block;
    height: 100%;
    overflow: hidden;
  }
  .top-intro-text > span > span > .mask {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 200%;
    background: linear-gradient(to bottom, #008ae6 0%, #008ae6 50%, rgba(0, 138, 230, 0) 100%);
  }
  .top-intro-text > span.visible > span > .mask {
    animation-fill-mode: both;
    animation-duration: 3s;
    animation-name: fadeInText;
    visibility: visible !important;
  }
  .top-intro-text > span.visible:nth-child(1) > span:nth-child(1) > .mask {
    animation-delay: 0;
  }
  .top-intro-text > span.visible:nth-child(1) > span:nth-child(2) > .mask {
    animation-delay: .75s;
  }
  .top-intro-text > span.visible:nth-child(2) > span:nth-child(1) > .mask {
    animation-delay: 1.5s;
  }
  .top-intro-text > span.visible:nth-child(2) > span:nth-child(2) > .mask {
    animation-delay: 2.25s;
  }
  .top-intro-text > span.visible:nth-child(3) > span:nth-child(1) > .mask {
    animation-delay: 3s;
  }
  .top-intro-text > span.visible:nth-child(3) > span:nth-child(2) > .mask {
    animation-delay: 3.75s;
  }
  .top-intro-text > span.visible:nth-child(4) > span:nth-child(1) > .mask {
    animation-delay: 4.5s;
  }
}

@media screen and (min-width: 576px), (orientation: landscape) {
  .top-intro-text > span {
    margin: 0;
    font-size: 1.4rem;
  }
  .top-intro-text > span > .mask {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 200%;
    background: linear-gradient(to bottom, #008ae6 0%, #008ae6 50%, rgba(0, 138, 230, 0) 100%);
  }
  .top-intro-text > span.visible > .mask {
    animation-fill-mode: both;
    animation-duration: 2s;
    animation-name: fadeInText;
    visibility: visible !important;
  }
  .top-intro-text > span.visible:nth-child(1) > .mask {
    animation-delay: 0;
  }
  .top-intro-text > span.visible:nth-child(2) > .mask {
    animation-delay: 1.5s;
  }
  .top-intro-text > span.visible:nth-child(3) > .mask {
    animation-delay: 3s;
  }
  .top-intro-text > span.visible:nth-child(4) > .mask {
    animation-delay: 4.5s;
  }
}

@media print, screen and (min-width: 992px) {
  .top-intro-text > span {
    margin-top: .5em;
    font-size: 1.9rem;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    /* 透明 */
  }
  100% {
    opacity: 1;
    /* 不透明 */
  }
}

@keyframes fadeInText {
  0% {
    transform: translateY(0);
    /* 透明 */
  }
  100% {
    transform: translateY(-100%);
    /* 不透明 */
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    /* 透明 */
    transform: translateY(50px);
    /* X軸方向に50px */
  }
  100% {
    opacity: 1;
    /* 不透明 */
    transform: translateY(0);
  }
}

.top-scroll-down {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 30px;
  margin: 0 auto;
  padding-top: 40px;
  text-align: center;
}

.top-scroll-down a {
  color: #ffffff;
  font-size: 1.3rem;
  text-decoration: none;
}

@media print, screen and (min-width: 992px) {
  .top-scroll-down a {
    font-size: 1.6rem;
  }
}

.top-scroll-down a:before {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
}

@media print, screen and (min-width: 992px) {
  .top-scroll-down a:before {
    top: 0;
    width: 30px;
    height: 30px;
  }
}

.top-scroll-down a.is-animation:before {
  animation: sdb 2s infinite;
}

@media screen and (max-width: 991px) and (orientation: landscape) {
  .top-scroll-down {
    right: 20px;
    bottom: 10px;
    left: auto;
    margin: 0 0 0 auto;
    padding-top: 30px;
    width: 60px;
    line-height: 1;
  }
  .top-scroll-down a {
    font-size: 1.1rem;
  }
}

@keyframes sdb {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    transform: rotate(-45deg) translate(-5px, 5px);
  }
  40% {
    transform: rotate(-45deg) translate(0, 0);
  }
}

/* top-contents */
.top-contents {
  position: relative;
}

@media print, screen and (min-width: 992px) {
  .top-contents {
    margin: 0 auto;
    padding: 0 40px;
  }
}

@media print, screen and (min-width: 1200px) {
  .top-contents {
    margin: 0 auto;
    max-width: 1280px;
  }
}

.top-contents-title {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 calc(60/750 * 100vw) 30px;
  font-size: calc(48/750 * 100vw);
  overflow: hidden;
}

@media screen and (min-width: 375px) {
  .top-contents-title {
    margin: 0 30px 1em;
    font-size: 2.4rem;
  }
}

@media print, screen and (min-width: 992px) {
  .top-contents-title {
    margin: 0 0 1em 0;
    font-size: 4.0rem;
    line-height: 1.2;
  }
}

.top-contents-title > span {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 1.5em;
  background-color: #ffffff;
  z-index: 1;
}

.top-contents-title > span:before, .top-contents-title > span:after {
  content: "";
  position: absolute;
  display: block;
  margin: auto 0;
  width: 2em;
  height: 2em;
}

.top-contents-title > span:before {
  top: 0;
  bottom: 0;
  left: 0;
  border: 2px solid #ffffff;
  border-left-color: #000000;
  border-right: none;
  border-radius: 50% 0 0 50%;
}

.top-contents-title > span:after {
  top: 0;
  bottom: 0;
  right: 0;
  border: 2px solid #ffffff;
  border-right-color: #000000;
  border-left: none;
  border-radius: 0 50% 50% 0;
}

.top-contents-title:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  border-top: 2px solid #000000;
  width: 100%;
  height: 0;
  z-index: 0;
}

.top-contents-title--about:before {
  border-top-color: #ffffff;
}

.top-contents-title--about > span {
  background-color: #008ae6;
  color: #ffffff;
}

.top-contents-title--about > span:before, .top-contents-title--about > span:after {
  border-color: #ffffff;
}

@media print, screen and (min-width: 992px) {
  .top-contents--about {
    padding-bottom: 120px;
  }
}

.top-contents--about > .inner {
  overflow: hidden;
}

/* top-slide */
.top-slide-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0 calc(60/750 * 100vw);
}

@media screen and (min-width: 375px) {
  .top-slide-bg {
    padding: 0 30px;
  }
}

@media print, screen and (min-width: 992px) {
  .top-slide-bg {
    margin: 0 auto;
    padding: 0 40px;
  }
  .top-slide-bg:before {
    content: "";
    position: absolute;
    top: 50px;
    right: 0;
    display: block;
    width: 135px;
    height: 49px;
    background-image: url(../img/top/bg_wave01.png);
    background-repeat: no-repeat;
  }
  .top-slide-bg:after {
    content: "";
    position: absolute;
    bottom: 30px;
    left: 72px;
    display: block;
    width: 200px;
    height: 49px;
    background-image: url(../img/top/bg_wave02.png);
    background-repeat: no-repeat;
  }
}

@media print, screen and (min-width: 1280px) {
  .top-slide-bg {
    width: 1280px;
    max-width: 1280px;
  }
}

.top-slide-container {
  position: relative;
}

@media print, screen and (min-width: 992px) {
  .top-slide-container {
    max-height: 600px;
  }
}

.top-slide {
  overflow: hidden;
}

.top-slide .slick-slide {
  margin: 0 15px;
}

@media print, screen and (min-width: 992px) {
  .top-slide .slick-slide {
    margin: 0 40px;
  }
}

.top-slide .slick-slide div {
  line-height: 0;
}

.top-slide .slick-slide img {
  margin: 0 auto;
}

.top-slide-arrows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(30/690 * 100vw);
  right: calc(30/690 * 100vw);
  margin: auto;
  height: 0;
}

@media print, screen and (min-width: 690px) {
  .top-slide-arrows {
    left: 0;
    right: 0;
    padding: 0;
    width: 630px;
  }
}

@media print, screen and (min-width: 992px) {
  .top-slide-arrows {
    width: 100%;
  }
}

@media print, screen and (min-width: 1280px) {
  .top-slide-arrows {
    width: 1200px;
    max-width: 1200px;
  }
}

.top-slide-arrows .slick-arrow {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 44px;
  height: 44px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 15px 33px;
  z-index: 100;
  transform: scale(0.8);
}

@media print, screen and (min-width: 992px) {
  .top-slide-arrows .slick-arrow {
    transform: none;
  }
}

.top-slide-arrows .slick-arrow.slick-prev {
  left: 5px;
  background-image: url(../img/top/icon_arrow_left.svg);
}

@media print, screen and (min-width: 992px) {
  .top-slide-arrows .slick-arrow.slick-prev {
    left: 40px;
  }
}

@media print, screen and (min-width: 1280px) {
  .top-slide-arrows .slick-arrow.slick-prev {
    left: 0;
  }
}

.top-slide-arrows .slick-arrow.slick-next {
  right: 5px;
  background-image: url(../img/top/icon_arrow_right.svg);
}

@media print, screen and (min-width: 992px) {
  .top-slide-arrows .slick-arrow.slick-next {
    right: 40px;
  }
}

@media print, screen and (min-width: 1280px) {
  .top-slide-arrows .slick-arrow.slick-next {
    right: 0;
  }
}

.top-slide-dots .slick-dots {
  list-style: none;
  margin: 0;
  transform: translate(0, -50%);
  padding-left: 0;
  display: flex;
  justify-content: center;
}

.top-slide-dots .slick-dots > li {
  margin: 0;
  width: 71.5px;
  height: 71.5px;
  transform: scale(0.61538);
  cursor: pointer;
}

@media print, screen and (min-width: 992px) {
  .top-slide-dots .slick-dots > li {
    width: 130px;
    height: 130px;
  }
}

.top-slide-dots .slick-dots > .slick-active {
  transform: scale(1);
}

.bg-lighthouse {
  display: block;
  margin: 0 auto;
  width: 79px;
  height: 87.5px;
  background-image: url(../img/top/bg_lighthouse.png);
  background-repeat: no-repeat;
  background-size: cover;
}

@media print, screen and (min-width: 992px) {
  .bg-lighthouse {
    position: absolute;
    right: 100px;
    bottom: -100px;
    width: 158px;
    height: 175px;
  }
}

.bg-compass {
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 58px;
  background-image: url(../img/top/bg_compass.png);
  background-repeat: no-repeat;
  background-size: cover;
}

@media print, screen and (min-width: 992px) {
  .bg-compass {
    position: absolute;
    left: 120px;
    top: 260px;
    width: 200px;
    height: 116px;
  }
}

.bg-yacht {
  position: absolute;
  top: -95px;
  right: 30px;
  display: block;
  width: 66px;
  height: 78px;
  background-image: url(../img/top/bg_yacht.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 100;
}

@media print, screen and (min-width: 992px) {
  .bg-yacht {
    top: -160px;
    right: 100px;
    width: 132px;
    height: 156px;
  }
}

.bg-coral {
  position: absolute;
  bottom: 0px;
  right: 15px;
  display: block;
  width: 115.5px;
  height: 114.8px;
  background-image: url(../img/top/bg_coral.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}

@media print, screen and (min-width: 992px) {
  .bg-coral {
    bottom: 0px;
    right: 30px;
    width: 165px;
    height: 164px;
  }
}

.blog-detail {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
}

@media print, screen and (min-width: 992px) {
  .blog-detail {
    margin-top: 80px;
  }
}

.bg-wave {
  width: 100%;
  height: 54px;
  background-image: url(../img/shared/bg_footer_sp.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% 54px;
}

@media print, screen and (min-width: 992px) {
  .bg-wave {
    height: 76px;
    background-image: url(../img/shared/bg_footer.svg);
    background-position: center top;
    background-size: 100% 187px;
  }
}

/* action */
.timeline {
  position: relative;
  margin-top: 20px;
}

@media print, screen and (min-width: 992px) {
  .timeline {
    margin-top: 80px;
    padding-bottom: 50px;
  }
  .timeline:before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    border-left: 2px solid #000000;
    left: 50%;
    top: 0;
  }
}

.timeline-item {
  position: relative;
}

@media print, screen and (max-width: 991px) {
  .timeline-item {
    padding-bottom: 40px;
  }
  .timeline-item:nth-child(n+2) {
    padding-top: 10px;
  }
  .timeline-item:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 40px;
    border-left: 2px solid #000000;
  }
}

@media print, screen and (min-width: 992px) {
  .timeline-item {
    width: 50%;
    margin-bottom: 50px;
  }
  .timeline-item:before {
    content: "";
    position: absolute;
    top: 10px;
    width: 110px;
    height: 0;
    border-top: 2px solid #000000;
  }
  .timeline-item:after {
    content: "";
    position: absolute;
    top: 0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #008ae6;
  }
}

@media print, screen and (min-width: 992px) {
  .timeline-item:nth-child(odd) {
    left: 50%;
  }
  .timeline-item:nth-child(odd):before {
    left: 0;
  }
  .timeline-item:nth-child(odd):after {
    left: -9.5px;
  }
}

@media print, screen and (min-width: 992px) {
  .timeline-item:nth-child(even):before {
    right: 0;
  }
  .timeline-item:nth-child(even):after {
    right: -10.5px;
  }
}

.action {
  position: relative;
}

@media print, screen and (max-width: 991px) {
  .action-header {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}

@media print, screen and (min-width: 992px) {
  .action-header ~ .action-content {
    margin-top: 100px;
  }
}

@media print, screen and (min-width: 992px) {
  .timeline-item:nth-child(odd) .action-content {
    padding-left: 140px;
  }
  .timeline-item:nth-child(even) .action-content {
    padding-right: 140px;
  }
}

.action-content-more {
  display: none;
}

.action-content-more.is-visible {
  display: block;
}

.action-date {
  margin: 0;
  color: #008ae6;
  font-size: calc(32/750 * 100vw);
  font-weight: bold;
  text-align: center;
}

@media screen and (min-width: 576px) {
  .action-date {
    font-size: 1.6rem;
  }
}

@media print, screen and (min-width: 992px) {
  .action-date {
    position: absolute;
    font-size: 2.0rem;
    line-height: 1;
    z-index: 1;
  }
  .timeline-item:nth-child(odd) .action-date {
    top: 0;
    left: -8.5em;
  }
  .timeline-item:nth-child(even) .action-date {
    top: 0;
    right: -8.5em;
  }
}

.action-disc {
  position: relative;
  margin-top: 10px;
  width: 20px;
  height: 20px;
}

.action-disc:after {
  content: "";
  position: absolute;
  top: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: #008ae6;
}

@media print, screen and (min-width: 992px) {
  .action-disc {
    display: none;
  }
}

.action-image {
  position: relative;
  margin-top: 10px;
  border: 3px solid #008ae6;
  border-radius: 50%;
  width: calc(442/750 * 100vw);
  height: calc(442/750 * 100vw);
  overflow: hidden;
}

@media screen and (min-width: 576px) {
  .action-image {
    width: 221px;
    height: 221px;
  }
}

@media print, screen and (min-width: 992px) {
  .action-image {
    position: absolute;
    top: 10px;
    margin-top: 0;
    width: 158px;
    height: 158px;
    z-index: 1;
  }
  .timeline-item:nth-child(odd) .action-image {
    left: 0;
    transform: translate(-50%, -50%);
  }
  .timeline-item:nth-child(odd) .action-image + .action-date {
    transform: translate(-50%, 0);
  }
  .timeline-item:nth-child(even) .action-image {
    right: 0;
    transform: translate(50%, -50%);
  }
  .timeline-item:nth-child(even) .action-image + .action-date {
    transform: translate(50%, 0);
  }
}

.action-image > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
  max-width: none;
}

.action-title {
  font-size: calc(32/750 * 100vw);
}

@media screen and (min-width: 576px) {
  .action-title {
    font-size: 1.6rem;
  }
}

@media print, screen and (min-width: 992px) {
  .action-title {
    font-size: 1.8rem;
    line-height: 1.55556;
  }
}

.action-text {
  font-size: calc(30/750 * 100vw);
}

@media screen and (min-width: 576px) {
  .action-text {
    font-size: 1.5rem;
  }
}

@media print, screen and (min-width: 992px) {
  .action-text {
    font-size: 1.6rem;
    line-height: 1.625;
  }
}

.action-detail {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

/* about */
.about {
  position: relative;
}

.about--vision:before {
  content: "";
  position: absolute;
  left: -20px;
  top: 0;
  display: block;
  width: 149px;
  height: 71px;
  background-image: url(../img/top/bg_vision.png);
  background-repeat: no-repeat;
  background-size: cover;
}

@media print, screen and (min-width: 992px) {
  .about--vision:before {
    left: -40px;
    top: 4px;
    width: 298px;
    height: 142px;
  }
}

.about--motive:before {
  content: "";
  position: absolute;
  right: -26px;
  top: 8px;
  display: block;
  width: 165px;
  height: 75.5px;
  background-image: url(../img/top/bg_motive.png);
  background-repeat: no-repeat;
  background-size: cover;
}

@media print, screen and (min-width: 992px) {
  .about--motive:before {
    right: -40px;
    top: -4px;
    width: 330px;
    height: 151px;
  }
}

.about--motive:after {
  content: "";
  position: absolute;
  left: -30px;
  top: -50px;
  display: block;
  width: 134.4px;
  height: 72.8px;
  background-image: url(../img/top/bg_fish01.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}

@media print, screen and (min-width: 992px) {
  .about--motive:after {
    left: 80px;
    top: -34px;
    width: 192px;
    height: 104px;
  }
}

.about--overview:before {
  content: "";
  position: absolute;
  left: -20px;
  top: -16px;
  display: block;
  width: 212px;
  height: 88px;
  background-image: url(../img/top/bg_overview.png);
  background-repeat: no-repeat;
  background-size: cover;
}

@media print, screen and (min-width: 992px) {
  .about--overview:before {
    left: -40px;
    top: -44px;
    width: 424px;
    height: 176px;
  }
}

.about--overview:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -362px;
  display: block;
  width: 135.8px;
  height: 72.8px;
  background-image: url(../img/top/bg_fish02.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}

@media print, screen and (min-width: 992px) {
  .about--overview:after {
    right: 40px;
    top: -44px;
    width: 194px;
    height: 104px;
  }
}

.about--member {
  padding-bottom: 50px;
}

.about--member:before {
  content: "";
  position: absolute;
  right: -20px;
  top: -4px;
  display: block;
  width: 178px;
  height: 79px;
  background-image: url(../img/top/bg_member.png);
  background-repeat: no-repeat;
  background-size: cover;
}

@media print, screen and (min-width: 992px) {
  .about--member:before {
    right: -40px;
    top: -4px;
    width: 356px;
    height: 158px;
  }
}

@media print, screen and (min-width: 992px) {
  .about--member {
    padding-bottom: 0;
  }
}

.about-title {
  margin: 40px 0 0 0;
  color: #ffffff;
  font-weight: normal;
  text-align: center;
  font-size: calc(40/750 * 100vw);
}

@media screen and (min-width: 576px) {
  .about-title {
    font-size: 2.0rem;
  }
}

@media print, screen and (min-width: 992px) {
  .about-title {
    margin-top: 80px;
    font-size: 3.6rem;
  }
}

@media print, screen and (max-width: 991px) {
  .about--vision .about-title {
    margin-top: 0;
  }
}

.about-text {
  position: relative;
  margin: 1.5em 0 0 0;
  color: #ffffff;
  z-index: 1;
}

.about-text:first-child {
  margin-top: 0;
}

.about-text--vision {
  font-size: calc(36/750 * 100vw);
  text-align: center;
}

@media screen and (min-width: 375px) {
  .about-text--vision {
    font-size: 1.8rem;
  }
}

@media print, screen and (min-width: 992px) {
  .about-text--vision {
    font-size: 2.0rem;
  }
}

.company-profile {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media print, screen and (min-width: 992px) {
  .company-profile {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.company-profile > dt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0 0 0;
  padding: 10px 15px;
  background-color: #ffffff;
  font-weight: bold;
}

@media print, screen and (min-width: 992px) {
  .company-profile > dt {
    margin: 4px 0 0 0;
    padding: 25px 0;
    width: 250px;
  }
}

.company-profile > dd {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0 0 0;
  padding: 15px;
  background-color: #ffffff;
}

@media print, screen and (min-width: 992px) {
  .company-profile > dd {
    justify-content: flex-start;
    margin: 4px 0 0 4px;
    padding: 25px 80px;
    width: calc(100% - 254px);
    min-height: 98px;
  }
}

.business-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.business-item {
  padding-left: 1.25em;
  text-indent: -.625em;
  font-feature-settings: "palt" 1;
}

.business-label {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
}

.business-text {
  margin: 0;
  padding-left: 1.25em;
}

.icon-disc {
  margin-right: .25em;
}

.icon-disc:before {
  content: "\25C9";
  display: inline-block;
}

.ceo-career-title {
  margin: 1em 0 0 0;
  color: #ffffff;
  font-size: calc(36/750 * 100vw);
  text-align: center;
}

@media screen and (min-width: 576px) {
  .ceo-career-title {
    font-size: 1.8rem;
  }
}

@media print, screen and (min-width: 992px) {
  .ceo-career-title {
    font-size: 2.4rem;
  }
}

.ceo-career-detail {
  margin: 0;
}

.ceo-career-detail > dt {
  margin: 1.5em 0 0 0;
  color: #ffffff;
  font-size: calc(34/750 * 100vw);
  font-feature-settings: "palt" 1;
}

@media screen and (min-width: 576px) {
  .ceo-career-detail > dt {
    font-size: 1.7rem;
  }
}

@media print, screen and (min-width: 992px) {
  .ceo-career-detail > dt {
    font-size: 2.0rem;
  }
}

.ceo-career-detail > dd {
  margin: 0;
}

/* other-business */
.other-business-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 14px;
  background-image: url(../img/shared/bg_wavy.png);
  background-repeat: repeat-x;
  background-position: left bottom;
}

.other-business-header > img {
  width: 72px;
  height: 72px;
}

@media print, screen and (min-width: 992px) {
  .other-business-header > img {
    width: 103px;
    height: 103px;
  }
}

.other-business-title {
  margin: .8em 0 0 0;
  color: #008ae6;
  font-size: calc(36/750 * 100vw);
  line-height: 1.44444;
}

@media screen and (min-width: 576px) {
  .other-business-title {
    font-size: 1.8rem;
  }
}

@media print, screen and (min-width: 992px) {
  .other-business-title {
    font-size: 2.6rem;
    line-height: 1.38462;
  }
}

.other-business-body {
  margin-top: 20px;
}

@media print, screen and (min-width: 992px) {
  .other-business-body {
    margin-top: 30px;
  }
}

@media print {
  @page {
    margin: 0;
  }
  @page {
    size: landscape;
    size: portrait;
  }
  body:not(.map) {
    zoom: 0.5;
  }
  body {
    -webkit-print-color-adjust: exact;
  }
}
