/**
 * Layout Style
 */
.l-wrapper {
  position: relative;
  top: 0;
  width: 100%;
  min-height: 100%;
  transition: top 1s;
  &.scrollready {
    top: 20%;
    transition: none;
  }
  &.scrollup {
    top: 0;
    transition: top 1s;
  }
}
.l-header {
  position: fixed;
  width: 100%;
  height: 144px * .5;
  z-index: 101;
  // transform: translate3d(0px, 0px, 200px);
  background-color: #ffffff;
  @include mq-above(md) {
    height: auto;
  }
  > .inner {
    padding-left: 15px;
    padding-right: 15px;
    @include mq-above(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.l-gnav {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  &.is-open {
    display: flex;
    visibility: visible;
  }
  @include mq-below(md) {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: #ffffff;
    overflow-y: auto;
    visibility: hidden;
  }
  @include mq-above(md) {
    position: static;
    display: block;
    width: auto;
  }
}
.l-main {
  position: relative;
  padding-top: 72px;
  padding-bottom: 50px + 107;
  width: 100%;
  @include mq-above(sm) {
    padding-bottom: 50px + 84;
  }
  @include mq-above(md) {
    padding-top: 93px;
    padding-bottom: 75px + 120;
  }
  .home & {
    padding-bottom: 50px + 321;
    @include mq-above(sm) {
      padding-bottom: 50px + 240;
    }
    @include mq-above(md) {
      padding-bottom: 75px + 359;
    }
  }
}
.l-top-intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $key-color;
  z-index: 9999;
  transition: top 1s;
  &.scrollup {
    top: -100%;
  }
}
.l-top-slide {
  position: relative;
  overflow: hidden;
}
.l-top-action {
  margin-top: 50px;
  @include mq-above(md) {
    margin-top: 100px;
  }
}
.l-top-about {
  position: relative;
  margin-top: 50px;
  @include mq-above(md) {
    margin-top: 180px;
  }
  &-inner {
    background-color: $key-color;
  }
}
.l-top-other-business {
  margin-top: 50px;
  margin-bottom: 50px;
  @include mq-above(md) {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}
.l-other-business {
  @include mq-above(md) {
    display: flex;
    justify-content: space-between;
    margin: 60px -25px 0;
  }
  &-col {
    @include mq-above(md) {
      padding: 0 25px;
      width: 560px;
    }
    &:nth-child(n+2) {
      margin-top: 24px;
      @include mq-above(md) {
        margin-top: 0;
      }
    }
  }
}
.l-page-container {
  @include mq-above(md) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
.l-page-main {
  @include mq-above(md) {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.l-page-aside {
  margin-top: 55px;
  @include mq-above(md) {
    margin-top: 0;
    margin-right: 80px;
    width: 244px;
  }
}
.l-footer {
  position: relative;
  margin-top: -107px;
  padding-bottom: 30px;
  height: 107px;
  background-color: $key-color;
  @include mq-above(sm) {
    margin-top: -84px;
    height: 84px;
  }
  @include mq-above(md) {
    margin-top: -120px;
    padding-bottom: 60px;
    height: 120px;
  }
  .home & {
    margin-top: -321px;
    height: 321px;
    @include mq-above(sm) {
      margin-top: -240px;
      height: 240px;
    }
    @include mq-above(md) {
      margin-top: -359px;
      height: 359px;
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: -54px;
    width: 100%;
    height: 54px;
    background-image: url(../img/shared/bg_footer_sp.svg);
    background-repeat: no-repeat;
    background-size: 100% 100px;
    z-index: 99;
    @include mq-above(md) {
      top: -75px;
      height: 76px;
      background-image: url(../img/shared/bg_footer.svg);
      background-size: 100% 187px;
    }
  }
}
.l-page-top {
  position: fixed;
  right: 0;
  bottom: 100px;
  z-index: 100;
}
