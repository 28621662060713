@media print {
  @page {
    margin: 0;
  }
  @page {
    size: landscape;
    size: portrait;
  }
  body:not(.map) {
    zoom: 0.5;
  }
  body {
    -webkit-print-color-adjust: exact;
  }
}
