/**
 * Header Style
 */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
  @include mq-above(md) {
    margin: 0 auto;
    padding: 15px;
  }
  @include mq-above(lg) {
    width: $inner-width;
  }
}
.logo {
  width: 202px * .5;
  height: 64px * .5;
  z-index: 102;
  @include mq-above(md) {
    width: 202px;
    height: 63px;
  }
}
.menu-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  width: 26px;
  height: 22px;
  cursor: pointer;
  z-index: 102;
  @include mq-above(md) {
    display: none;
  }
  &:before,
  &:after {
    content: "";
  }
  > span,
  &:before,
  &:after {
    display: block;
    border-top: 5px * .5 solid $key-color;
  }
  &.is-open {
    > span {
      opacity: 0;
    }
    &:before {
      transform: translate(0, 10px) rotate(45deg);
    }
    &:after {
      transform: translate(0, -10px) rotate(-45deg);
    }
  }
}
