// color
$key-color: #008ae6;
$text-color-black: #000000;
$text-color-white: #ffffff;
$border-color-gray: #505050;

// font
$font-sans-serif: "游ゴシック体", YuGothic, "游ゴシック", "メイリオ", Meiryo, sans-serif;
$font-sans-serif-ie: "メイリオ", Meiryo, sans-serif;
$font-serif: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "游明朝", "Yu Mincho", "YuMincho", "HGS明朝E", "ＭＳ Ｐ明朝", serif;

// width
$inner-width: 1200px;
$content-width: 950px;
