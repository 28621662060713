/**
 * Button Style
 */

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $key-color;
  width: 100%;
  height: 52px;
  background: #ffffff;;
  color: $key-color;
  font-size: calc(36/750 * 100vw);
  line-height: 1;
  text-decoration: none;
  text-align: center;
  @include mq-above(xxs) {
    font-size: 1.8rem;
  }
  @include mq-above(md) {
    margin: 0 20px;
    border-width: 3px;
    width: 330px;
    height: 75px;
    font-size: 2.6rem;
  }
  &--contact {
    background-color: $key-color;
    color: $text-color-white;
    border-color: $text-color-white;
  }
  &.is-disabled {
    pointer-events: none;
  }
}
.input-submit {
  appearance: none;
  padding: 0;
  border: 2px solid $border-color-gray;
  border-radius: 0;
  width: 100%;
  height: 52px;
  background: transparent;
  font-size: calc(36/750 * 100vw);
  line-height: 1;
  text-align: center;
  outline: none;
  &[name="submit"],
  &[name="submitConfirm"] {
    border-color: $key-color;
    color: $key-color;
  }
  @include mq-above(xxs) {
    font-size: 1.8rem;
  }
  @include mq-above(md) {
    margin: 0 20px;
    border-width: 3px;
    width: 330px;
    height: 75px;
    font-size: 2.6rem;
  }
  &--back {
    @include mq-below(md) {
      margin-bottom: 20px;
    }
  }
}
