/**
 * Top page Style
 */

/* top intro */
.line1-str {
  width: 28px;
  // height: 28px;
  // img {
  //   height: 100%;
  // }
}
.top-intro-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.top-intro {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -50px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 314px * .5;
    height: 122px * .5;
    background-image: url(../img/top/bg_intro_wave01.png);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    @media screen and (max-width: 575px) and (orientation: portrait) {
      right: -66px;
      top: 190px;
    }
    @media screen and (min-width: 576px), (orientation: landscape) {
      right: -140px;
      top: 140px;
    }
    @include mq-above(md) {
      right: -266px;
      top: 256px;
    }
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 314px * .5;
    height: 128px * .5;
    background-image: url(../img/top/bg_intro_wave02.png);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    @media screen and (max-width: 575px) and (orientation: portrait) {
      left: -100px;
      top: 320px;
    }
    @media screen and (min-width: 576px), (orientation: landscape) {
      left: -140px;
      top: 250px;
    }
    @include mq-above(md) {
      left: -280px;
      top: 440px;
    }
  }
}
.top-intro-logo {
  width: 188px * .5;
  @include mq-above(md) {
    width: 180px * .8;
    height: 219px * .8;
  }
}
.top-intro-slogan {
  color: $text-color-white;
  font-size: 2.2rem;
  text-align: center;
  @include mq-above(md) {
    font-size: 2.8rem;
  }
}
.top-intro-slogan {
  margin: 0;
  > span {
    position: relative;
    display: block;
    // visibility: hidden;
    margin: 1em 0;
    height: 100%;
    // overflow: hidden;
    // &:after {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   display: block;
    //   width: 100%;
    //   height: 200%;
    //   background: linear-gradient(to bottom, rgba(0,138,230,1) 0%,rgba(0,138,230,1) 50%,rgba(0,138,230,0) 100%);
    // }
    // &.visible:after {
    //   animation-fill-mode: both;
    //   animation-duration: 2s;
    //   animation-name: fadeInText;
    //   visibility: visible !important;
    // }
  }
}
.top-intro-text {
  margin: 0;
  > span {
    position: relative;
    display: block;
    margin: 0;
    height: 100%;
    color: $text-color-white;
    font-size: 1.5rem;
    text-align: center;
    overflow: hidden;
    // visibility: hidden;
    @media screen and (max-width: 575px) and (orientation: portrait) {
      > span {
        position: relative;
        display: block;
        height: 100%;
        overflow: hidden;
        > .mask {
          // content: "";
          position: absolute;
          top: 0;
          display: block;
          width: 100%;
          height: 200%;
          background: linear-gradient(to bottom, rgba(0,138,230,1) 0%,rgba(0,138,230,1) 50%,rgba(0,138,230,0) 100%);
        }
      }
      &.visible > span > .mask {
        animation-fill-mode: both;
        animation-duration: 3s;
        animation-name: fadeInText;
        visibility: visible !important;
      }
      &.visible:nth-child(1) {
        > span:nth-child(1) > .mask {
          animation-delay: 0;
        }
        > span:nth-child(2) > .mask {
          animation-delay: .75s;
        }
      }
      &.visible:nth-child(2) {
        > span:nth-child(1) > .mask {
          animation-delay: 1.5s;
        }
        > span:nth-child(2) > .mask {
          animation-delay: 2.25s;
        }
      }
      &.visible:nth-child(3) {
        > span:nth-child(1) > .mask {
          animation-delay: 3s;
        }
        > span:nth-child(2) > .mask {
          animation-delay: 3.75s;
        }
      }
      &.visible:nth-child(4) {
        > span:nth-child(1) > .mask {
          animation-delay: 4.5s;
        }
      }
    }
    @media screen and (min-width: 576px), (orientation: landscape) {
      margin: 0;
      font-size: 1.4rem;
      > .mask {
        // content: "";
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        height: 200%;
        background: linear-gradient(to bottom, rgba(0,138,230,1) 0%,rgba(0,138,230,1) 50%,rgba(0,138,230,0) 100%);
      }
      &.visible > .mask {
        animation-fill-mode: both;
        animation-duration: 2s;
        animation-name: fadeInText;
        visibility: visible !important;
      }
      &.visible:nth-child(1) > .mask {
        animation-delay: 0;
      }
      &.visible:nth-child(2) > .mask {
        animation-delay: 1.5s;
      }
      &.visible:nth-child(3) > .mask {
        animation-delay: 3s;
      }
      &.visible:nth-child(4) > .mask  {
        animation-delay: 4.5s;
      }
    }
    @include mq-above(md) {
      margin-top: .5em;
      font-size: 1.9rem;
    }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;/* 透明 */
  }
  100% {
    opacity: 1;/* 不透明 */
  }
}
@keyframes fadeInText {
  0% {
    transform: translateY(0);/* 透明 */
  }
  100% {
    transform: translateY(-100%);/* 不透明 */
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;/* 透明 */
    transform: translateY(50px);/* X軸方向に50px */
  }
  100% {
    opacity: 1;/* 不透明 */
    transform: translateY(0);
  }
}
.top-scroll-down {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 30px;
  margin: 0 auto;
  padding-top: 40px;
  text-align: center;
  a {
    color: $text-color-white;
    font-size: 1.3rem;
    text-decoration: none;
    @include mq-above(md) {
      font-size: 1.6rem;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      width: 20px;
      height: 20px;
      margin: 0 auto;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(-45deg);
      @include mq-above(md) {
        top: 0;
        width: 30px;
        height: 30px;
      }
    }
    &.is-animation:before {
      animation: sdb 2s infinite;
    }
  }
  @media screen and (max-width: 991px) and (orientation: landscape) {
    right: 20px;
    bottom: 10px;
    left: auto;
    margin: 0 0 0 auto;
    padding-top: 30px;
    width: 60px;
    line-height: 1;
    a {
      font-size: 1.1rem;
    }
  }
}
@keyframes sdb {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    transform: rotate(-45deg) translate(-5px, 5px);
  }
  40% {
    transform: rotate(-45deg) translate(0, 0);
  }
}


/* top-contents */
.top-contents {
  position: relative;
  @include mq-above(md) {
    margin: 0 auto;
    padding: 0 40px;
  }
  @include mq-above(lg) {
    margin: 0 auto;
    max-width: $inner-width + 80;
  }
  &-title {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0 calc(60/750 * 100vw) 30px;
    font-size: calc(48/750 * 100vw);
    overflow: hidden;
    @include mq-above(xxs) {
      margin: 0 60px * .5 1em;
      font-size: 2.4rem;
    }
    @include mq-above(md) {
      margin: 0 0 1em 0;
      font-size: 4.0rem;
      line-height: 1.2;
    }
    > span {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 1.5em;
      background-color: $text-color-white;
      z-index: 1;
      &:before,
      &:after {
        content: "";
        position: absolute;
        display: block;
        margin: auto 0;
        width: 2em;
        height: 2em;
      }
      &:before {
        top: 0;
        bottom: 0;
        left: 0;
        border: 2px solid #ffffff;
        border-left-color: $text-color-black;
        border-right: none;
        border-radius: 50% 0 0 50%;
        // transform: rotate(-45deg);
      }
      &:after {
        top: 0;
        bottom: 0;
        right: 0;
        border: 2px solid #ffffff;
        border-right-color: $text-color-black;
        border-left: none;
        border-radius: 0 50% 50% 0;
      }
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto 0;
      border-top: 2px solid $text-color-black;
      width: 100%;
      height: 0;
      z-index: 0;
    }
    &--about {
      &:before {
        border-top-color: $text-color-white;
      }
      > span {
        background-color: $key-color;
        color: $text-color-white;
        &:before,
        &:after {
          border-color: $text-color-white;
        }
      }
    }
  }
  &--about {
    @include mq-above(md) {
      padding-bottom: 120px;
    }
    > .inner {
      overflow: hidden;
    }
  }
}
/* top-slide */
.top-slide-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0 calc(60/750 * 100vw);
  @include mq-above(xxs) {
    padding: 0 60px * .5;
  }
  @include mq-above(md) {
    margin: 0 auto;
    padding: 0 40px;
    &:before {
      content: "";
      position: absolute;
      top: 50px;
      right: 0;
      display: block;
      width: 135px;
      height: 49px;
      background-image: url(../img/top/bg_wave01.png);
      background-repeat: no-repeat;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 30px;
      left: 72px;
      display: block;
      width: 200px;
      height: 49px;
      background-image: url(../img/top/bg_wave02.png);
      background-repeat: no-repeat;
    }
  }
  @media print, screen and (min-width: 1280px) {
    width: $inner-width + 80;
    max-width: $inner-width + 80;
  }
}
.top-slide-container {

  position: relative;
  // height: calc(896/750 * 100vw);
  // @include mq-above(md) {
  //   height: calc(600/1200 * 100vw);
  // }
  @include mq-above(md) {//1200以上
    max-height: 600px;
  }
}
.top-slide {
  overflow: hidden;
  // height: calc(896/750 * 100vw);
  // @media print, screen and (min-width: 690px) {
  //   height: 896px;
  // }
  // @include mq-above(md) {
  //   height: 600px;
  // }
  // @include mq-above(lg) {
  //   max-height: 600px;
  // }
  .slick-slide {
    margin: 0 15px;
    @include mq-above(md) {
      margin: 0 40px;
    }
    div {
      line-height: 0;
    }
    img {
      margin: 0 auto;
    }
  }
}
.top-slide-arrows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(30/690 * 100vw);
  right: calc(30/690 * 100vw);
  margin: auto;
  height: 0;
  @media print, screen and (min-width: 690px) {
    left: 0;
    right: 0;
    padding: 0;
    width: 630px;
  }
  @include mq-above(md) {
    width: 100%;
  }
  @media print, screen and (min-width: 1280px) {
    width: $inner-width;
    max-width: $inner-width;
  }
  .slick-arrow {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 44px;
    height: 44px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px 33px;
    z-index: 100;
    transform: scale(.8);
    @include mq-above(md) {
      transform: none;
    }
    &.slick-prev {
      left: 5px;
      background-image: url(../img/top/icon_arrow_left.svg);
      @include mq-above(md) {
        left: 40px;
      }
      @media print, screen and (min-width: 1280px) {
        left: 0;
      }
    }
    &.slick-next {
      right: 5px;
      background-image: url(../img/top/icon_arrow_right.svg);
      @include mq-above(md) {
        right: 40px;
      }
      @media print, screen and (min-width: 1280px) {
        right: 0;
      }
    }
  }
}
.top-slide-dots {
  .slick-dots {
    list-style: none;
    margin: 0;
    transform: translate(0, -50%);
    padding-left: 0;
    display: flex;
    justify-content: center;
    > li {
      margin: 0;
      width: 143px * .5;
      height: 143px * .5;
      transform: scale(.615384615);
      cursor: pointer;
      @include mq-above(md) {
        width: 130px;
        height: 130px;
      }
    }
    > .slick-active {
      transform: scale(1);
    }
  }
}
.bg-lighthouse {
  display: block;
  margin: 0 auto;
  width: 158px * .5;
  height: 175px * .5;
  background-image: url(../img/top/bg_lighthouse.png);
  background-repeat: no-repeat;
  background-size: cover;
  @include mq-above(md) {
    position: absolute;
    right: 100px;
    bottom: -100px;
    width: 158px;
    height: 175px;
  }
}
.bg-compass {
  display: block;
  margin: 0 auto;
  width: 200px * .5;
  height: 116px * .5;
  background-image: url(../img/top/bg_compass.png);
  background-repeat: no-repeat;
  background-size: cover;
  @include mq-above(md) {
    position: absolute;
    left: 120px;
    top: 260px;
    width: 200px;
    height: 116px;
  }
}
.bg-yacht {
  position: absolute;
  top: -95px;
  right: 30px;
  display: block;
  width: 132px * .5;
  height: 156px * .5;
  background-image: url(../img/top/bg_yacht.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 100;
  @include mq-above(md) {
    top: -160px;
    right: 100px;
    width: 132px;
    height: 156px;
  }
}
.bg-coral {
  position: absolute;
  bottom: 0px;
  right: 15px;
  display: block;
  width: 165px * .7;
  height: 164px * .7;
  background-image: url(../img/top/bg_coral.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  @include mq-above(md) {
    bottom: 0px;
    right: 30px;
    width: 165px;
    height: 164px;
  }
}
.blog-detail {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
  @include mq-above(md) {
    margin-top: 80px;
  }
}
.bg-wave {
  width: 100%;
  height: 54px;
  background-image: url(../img/shared/bg_footer_sp.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% 54px;
  @include mq-above(md) {
    height: 76px;
    background-image: url(../img/shared/bg_footer.svg);
    background-position: center top;
    background-size: 100% 187px;
  }
}
/* action */
.timeline {
  position: relative;
  margin-top: 20px;
  @include mq-above(md) {
    margin-top: 80px;
    padding-bottom: 50px;
    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 100%;
      border-left: 2px solid #000000;
      left: 50%;
      top: 0;
    }
  }
  &-item {
    position: relative;
    @include mq-below(md) {
      padding-bottom: 40px;
      &:nth-child(n+2) {
        padding-top: 10px;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 0;
        height: 40px;
        border-left: 2px solid #000000;
      }
    }
    @include mq-above(md) {
      width: 50%;
      margin-bottom: 50px;
      &:before {
        content: "";
        position: absolute;
        top: 10px;
        width: 110px;
        height: 0;
        border-top: 2px solid #000000;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: $key-color;
      }
    }
    &:nth-child(odd) {
      @include mq-above(md) {
        left: 50%;
        &:before {
          left: 0;
        }
        &:after {
          left: -9.5px;
        }
      }
    }
    &:nth-child(even) {
      @include mq-above(md) {
        &:before {
          right: 0;
        }
        &:after {
          right: -10.5px;
        }
      }
    }
  }
}
.action {
  position: relative;
  &-header {
    @include mq-below(md) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
    @include mq-above(md) {
      ~ .action-content {
        margin-top: 100px;
      }
    }
  }
  &-content {
    @include mq-above(md) {
      .timeline-item:nth-child(odd) & {
        padding-left: 140px;
      }
      .timeline-item:nth-child(even) & {
        padding-right: 140px;
      }
    }
  }
  &-content-more {
    display: none;
    &.is-visible {
      display: block;
    }
  }
  &-date {
    margin: 0;
    color: $key-color;
    font-size: calc(32/750 * 100vw);
    font-weight: bold;
    text-align: center;
    @include mq-above(xs) {
      font-size: 1.6rem;
    }
    @include mq-above(md) {
      position: absolute;
      font-size: 2.0rem;
      line-height: 1;
      z-index: 1;
      .timeline-item:nth-child(odd) & {
        top: 0;
        left: -8.5em;
      }
      .timeline-item:nth-child(even) & {
        top: 0;
        right: -8.5em;
      }
    }
  }
  &-disc {
    position: relative;
    margin-top: 10px;
    width: 20px;
    height: 20px;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background-color: $key-color;
    }
    @include mq-above(md) {
      display: none;
    }
  }
  &-image {
    position: relative;
    margin-top: 10px;
    border: 3px solid $key-color;
    border-radius: 50%;
    width: calc(442/750 * 100vw);
    height: calc(442/750 * 100vw);
    overflow: hidden;
    @include mq-above(xs) {
      width: 221px;
      height: 221px;
    }
    @include mq-above(md) {
      position: absolute;
      top: 10px;
      margin-top: 0;
      width: 158px;
      height: 158px;
      z-index: 1;
      .timeline-item:nth-child(odd) & {
        left: 0;
        transform: translate(-50%, -50%);
        + .action-date {
          transform: translate(-50%, 0);
        }
      }
      .timeline-item:nth-child(even) & {
        right: 0;
        transform: translate(50%, -50%);
        + .action-date {
          transform: translate(50%, 0);
        }
      }
    }
    > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: 100%;
      max-width: none;
    }
  }
  &-title {
    font-size: calc(32/750 * 100vw);
    @include mq-above(xs) {
      font-size: 1.6rem;
    }
    @include mq-above(md) {
      font-size: 1.8rem;
      line-height: (28/18);
    }
  }
  &-text {
    font-size: calc(30/750 * 100vw);
    @include mq-above(xs) {
      font-size: 1.5rem;
    }
    @include mq-above(md) {
      font-size: 1.6rem;
      line-height: (26/16);
    }
  }
}
.action-detail {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

/* about */
.about {
  position: relative;
  &--vision {
    &:before {
      content: "";
      position: absolute;
      left: -20px;
      top: 0;
      display: block;
      width: 298px * .5;
      height: 142px * .5;
      background-image: url(../img/top/bg_vision.png);
      background-repeat: no-repeat;
      background-size: cover;
      @include mq-above(md) {
        left: -40px;
        top: 4px;
        width: 298px;
        height: 142px;
      }
    }
  }
  &--motive {
    &:before {
      content: "";
      position: absolute;
      right: -26px;
      top: 8px;
      display: block;
      width: 330px * .5;
      height: 151px * .5;
      background-image: url(../img/top/bg_motive.png);
      background-repeat: no-repeat;
      background-size: cover;
      @include mq-above(md) {
        right: -40px;
        top: -4px;
        width: 330px;
        height: 151px;
      }
    }
    &:after {
      content: "";
      position: absolute;
      left: -30px;
      top: -50px;
      display: block;
      width: 192px * .7;
      height: 104px * .7;
      background-image: url(../img/top/bg_fish01.png);
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 0;
      @include mq-above(md) {
        left: 80px;
        top: -34px;
        width: 192px;
        height: 104px;
      }
    }
  }
  &--overview {
    &:before {
      content: "";
      position: absolute;
      left: -20px;
      top: -16px;
      display: block;
      width: 424px * .5;
      height: 176px * .5;
      background-image: url(../img/top/bg_overview.png);
      background-repeat: no-repeat;
      background-size: cover;
      @include mq-above(md) {
        left: -40px;
        top: -44px;
        width: 424px;
        height: 176px;
      }
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -362px;
      display: block;
      width: 194px * .7;
      height: 104px * .7;
      background-image: url(../img/top/bg_fish02.png);
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 0;
      @include mq-above(md) {
        right: 40px;
        top: -44px;
        width: 194px;
        height: 104px;
      }
    }
  }
  &--member {
    padding-bottom: 50px;
    &:before {
      content: "";
      position: absolute;
      right: -20px;
      top: -4px;
      display: block;
      width: 356px * .5;
      height: 158px * .5;
      background-image: url(../img/top/bg_member.png);
      background-repeat: no-repeat;
      background-size: cover;
      @include mq-above(md) {
        right: -40px;
        top: -4px;
        width: 356px;
        height: 158px;
      }
    }
    @include mq-above(md) {
      padding-bottom: 0;
    }
  }
  &-title {
    margin: 40px 0 0 0;
    color: $text-color-white;
    font-weight: normal;
    text-align: center;
    font-size: calc(40/750 * 100vw);
    @include mq-above(xs) {
      font-size: 2.0rem;
    }
    @include mq-above(md) {
      margin-top: 80px;
      font-size: 3.6rem;
    }
    .about--vision & {
      @include mq-below(md) {
        margin-top: 0;
      }
    }
  }
  &-text {
    position: relative;
    margin: 1.5em 0 0 0;
    color: #ffffff;
    z-index: 1;
    &:first-child {
      margin-top: 0;
    }
    &--vision {
      font-size: calc(36/750 * 100vw);
      text-align: center;
      @include mq-above(xxs) {
        font-size: 1.8rem;
      }
      @include mq-above(md) {
        font-size: 2.0rem;
      }
    }
  }
}
.company-profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include mq-above(md) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  > dt {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 0 0 0;
    padding: 10px 15px;
    background-color: #ffffff;
    font-weight: bold;
    @include mq-above(md) {
      margin: 4px 0 0 0;
      padding: 25px 0;
      width: 250px;
    }
  }
  > dd {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 0 0 0;
    padding: 15px;
    background-color: #ffffff;
    @include mq-above(md) {
      justify-content: flex-start;
      margin: 4px 0 0 4px;
      padding: 25px 80px;
      width: calc(100% - 254px);
      min-height: 98px;
    }
  }
}
.business {
  &-list {
    list-style: none;
    margin: 0;
    padding-left: 0;
  }
  &-item {
    padding-left: 1.25em;
    text-indent: -.625em;
    font-feature-settings: "palt" 1;
  }
  &-label {
    display: inline-flex;
    align-items: center;
    font-weight: bold;
  }
  &-text {
    margin: 0;
    padding-left: 1.25em;
  }
}
.icon-disc {
  margin-right: .25em;
  &:before {
    content: "\25C9";
    display: inline-block;
  }
}
.ceo-career {
  &-title {
    margin: 1em 0 0 0;
    color: $text-color-white;
    font-size: calc(36/750 * 100vw);
    text-align: center;
    @include mq-above(xs) {
      font-size: 1.8rem;
    }
    @include mq-above(md) {
      font-size: 2.4rem;
    }
  }
  &-detail {
    margin: 0;
    > dt {
      margin: 1.5em 0 0 0;
      color: $text-color-white;
      font-size: calc(34/750 * 100vw);
      font-feature-settings: "palt" 1;
      @include mq-above(xs) {
        font-size: 1.7rem;
      }
      @include mq-above(md) {
        font-size: 2.0rem;
      }
    }
    > dd {
      margin: 0;
    }
  }
}
/* other-business */
.other-business {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 14px;
    background-image: url(../img/shared/bg_wavy.png);
    background-repeat: repeat-x;
    background-position: left bottom;
    > img {
      width: 72px;
      height: 72px;
      @include mq-above(md) {
        width: 103px;
        height: 103px;
      }
    }
  }
  &-title {
    margin: .8em 0 0 0;
    color: $key-color;
    font-size: calc(36/750 * 100vw);
    line-height: (52/36);
    @include mq-above(xs) {
      font-size: 1.8rem;
    }
    @include mq-above(md) {
      font-size: 2.6rem;
      line-height: (36/26);
    }
  }
  &-body {
    margin-top: 20px;
    @include mq-above(md) {
      margin-top: 30px;
    }
  }
}
