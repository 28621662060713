/**
 * Base Style
 */
// font-face
@font-face {
  font-family: 'MyHiragino';
  src: local('HiraginoSans-W3'),
       local('ヒラギノ角ゴ ProN W3'),
       local('Hiragino Kaku Gothic ProN');
}
@font-face {
  font-family: 'MyHiragino';
  font-weight: bold;
  src: local('HiraginoSans-W6'),
       local('ヒラギノ角ゴ ProN W6'),
       local('Hiragino Kaku Gothic ProN W6');
}
@font-face {
  font-family: 'MyHiragino';
  font-weight: 800;
  src: local('HiraginoSans-W8'),
       local('ヒラギノ角ゴ StdN'),
       local('Hiragino Kaku Gothic StdN');
}
@font-face {
  font-family: 'MyYuGothic';
  src: local('Yu Gothic Medium');
}
@font-face {
  font-family: 'MyYuGothic';
  src: local('Yu Gothic Bold');
  font-weight: bold;
}
*,
*:after,
*:before {
  box-sizing: border-box;
}
html,
body {
  // min-width: 375px;
  height: 100%;
}
html {
  font-size: 62.5%;
}
body {
  margin: 0;
  min-height: 568px;
  color: $text-color-black;
  font-family: $font-sans-serif;
  font-size: calc(32/750 * 100vw);
  font-weight: 500;
  line-height: (29/16);
  overflow-y: scroll;
  @include mq-above(xxs) {
    font-size: 1.6rem;
  }
  @include mq-above(md) {
    font-size: 1.8rem;
    line-height: (30/18);
  }
}
_:lang(x)::-ms-backdrop, body {
  font-family: $font-sans-serif-ie;
}
.disabled-scroll {
  overflow: hidden;
}
a {
  color: $key-color;
  &:hover {
    opacity: .8;
  }
}
img {
  max-width: 100%;
  vertical-align: bottom;
}
// IE11向け
_:-ms-lang(x)::-ms-backdrop, img {
  &[src$=".svg"],
  &[src$=".svgz"] {
    width: 100%;
  }
}
// 0〜375px表示（スマホ縦）
.visible-xxs {
  display: block !important;
  @include mq-above(xxs) {
    display: none !important;
  }
}
// 0〜575px表示（スマホ縦）
.visible-xs {
  display: block !important;
  @include mq-above(xs) {
    display: none !important;
  }
}
// 0〜767px表示（スマホの縦横）
.visible-sp {
  display: block !important;
  @include mq-above(sm) {
    display: none !important;
  }
}
// 0〜991px表示（タブレット）
.visible-tablet {
  display: block !important;
  @include mq-above(md) {
    display: none !important;
  }
}
// 992px以上表示（PC）
.visible-pc {
  display: none !important;
  @include mq-above(md) {
    display: block !important;
  }
}
.inner {
  padding: 0 calc(60/750 * 100vw);
  @include mq-above(xxs) {
    padding: 0 60px * .5;
  }
  @include mq-above(md) {
    margin: 0 auto;
    padding: 0 40px;
  }
  @include mq-above(lg) {
    max-width: $inner-width;
  }
  &--narrow {
    @include mq-above(md) {
      padding: 0;
      width: calc(#{strip-unit($content-width)} / 1200 * 100vw);
    }
    @include mq-above(lg) {
      width: $content-width;
    }
  }
}
