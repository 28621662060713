/**
 * Nav Style
 */

.gnav {
  // @include mq-above(md) {
  //   display: flex;
  //   justify-content: flex-end;
  //   align-items: center;
  //   margin: 0 auto;
  //   padding: 40px 15px;
  //   height: 144px;
  // }
  @include mq-above(lg) {
    // width: 840px;
  }
}
.gnav-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
  > li {
    margin: calc(30/736*100vh) 0;
    text-align: center;
  }
  @include mq-above(md) {
    display: flex;
    > li {
      margin: 0 9px;
      &:first-child {
        display: none;
      }
    }
  }
  @include mq-above(lg) {
    > li {
      margin: 0 18px;
    }
  }
}
.gnav-label {
  padding: 10px 8px;
  border-bottom: 2px solid transparent;
  color: #000;
  font-size: 1.8rem;
  text-decoration: none;
  &.is-touch {
    color: $key-color;
    border-bottom-color: $key-color;
  }
  @include mq-above(md) {
    border-bottom-width: 4px;
    padding: 10px 8px;
    font-size: 1.6rem;
    &.is-current,
    &:hover {
      color: $key-color;
      border-bottom-color: $key-color;
    }
  }
}
/* category-list */
.category-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: -7px;
  padding-left: 0;
  @include mq-above(md) {
    margin: -10px;
  }
  > li {
    padding: 7px;
    width: 50%;
    @include mq-above(sm) {
      padding: 10px;
      width: 33.333333333%;
    }
    @include mq-above(md) {
      padding: 10px;
      width: 20%;
    }
  }
  &--post {
    @include mq-above(md) {
      flex-wrap: nowrap;
      flex-direction: column;
    }
    > li {
      width: 100%;
      @include mq-above(xs) {
        width: 50%;
      }
      @include mq-above(sm) {
        width: 33.333333333%;
      }
      @include mq-above(md) {
        width: 100%;
      }
    }
  }
}
.category-label {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $key-color;
  border-radius: 20px;
  height: 40px;
  background-color: #ffffff;
  color: $key-color;
  text-decoration: none;
  @include mq-above(md) {
    border-radius: 27px;
    height: 54px;
    font-size: 2.0rem;
    &:hover {
      background-color: $key-color;
      color: $text-color-white;
      opacity: 1;
    }
  }
  .current & {
    background-color: $key-color;
    color: $text-color-white;
  }
  .category-icon {
    margin-right: 7px;
    fill: $key-color;
    @include mq-above(md) {
      margin-right: 9px;
    }
    &--list {
      width: 23px;
      height: 16.5px;
      @include mq-above(md) {
        width: 31px;
        height: 22px;
      }
    }
    &--develop {
      width: 15px;
      height: 23px;
      @include mq-above(md) {
        width: 19px;
        height: 30px;
      }
    }
    &--design {
      width: 22px;
      height: 20px;
      @include mq-above(md) {
        width: 30px;
        height: 26px;
      }
    }
    &--welfare {
      width: 22px;
      height: 23.5px;
      @include mq-above(md) {
        width: 30px;
        height: 29px;
      }
    }
    &--care {
      width: 22px;
      height: 19.5px;
      @include mq-above(md) {
        width: 30px;
        height: 26px;
      }
    }
    &--community {
      width: 22.5px;
      height: 24px;
      @include mq-above(md) {
        width: 30px;
        height: 31px;
      }
    }
    &--seminar {
      width: 15.5px;
      height: 25px;
      @include mq-above(md) {
        width: 22px;
        height: 34px;
      }
    }
    &--action {
      width: 27px;
      height: 23px;
      @include mq-above(md) {
        width: 36px;
        height: 30px;
      }
    }
    &--other {
      width: 24.5px;
      height: 23px;
      @include mq-above(md) {
        width: 33px;
        height: 30px;
      }
    }
  }
  .current & .category-icon {
    fill: #ffffff;
  }
  @include mq-above(md) {
    &:hover .category-icon {
      fill: #ffffff;
    }
  }
}
.tag-list {
  list-style: none;
  margin: 20px -.5em -.5em;
  padding-left: 0;
  > li {
    display: inline-block;
    padding: .5em;
  }
}
/* pagination */
.navigation.pagination {
  position: relative;
}
.screen-reader-text {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}
.nav-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0 0;
  .page-numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    padding: 0 10px;
    border: 1px solid $border-color-gray;
    height: 35px;
    color: $border-color-gray;
    text-decoration: none;
    &.current {
      border-color: $key-color;
      color: $key-color;
    }
    &.prev,
    &.next {
      position: relative;
      @include mq-above(md) {
        font-size: 2.6rem;
        width: 200px;
        height: 69px;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        display: block;
        margin: auto 0;
        width: 6px;
        height: 6px;
        @include mq-above(md) {
          width: 12px;
          height: 12px;
        }
      }
    }
    &.prev {
      padding-left: 15px;
      @include mq-above(md) {
        padding-left: 20px;
      }
      &:before {
        left: 5px;
        border-top: 1px solid $border-color-gray;
        border-left: 1px solid $border-color-gray;
        transform: rotate(-45deg);
        @include mq-above(md) {
          left: 10px;
          border-width: 3px;
        }
      }
    }
    &.next {
      padding-right: 15px;
      @include mq-above(md) {
        padding-right: 20px;
      }
      &:before {
        right: 5px;
        border-bottom: 1px solid $border-color-gray;
        border-right: 1px solid $border-color-gray;
        transform: rotate(-45deg);
        @include mq-above(md) {
          right: 10px;
          border-width: 3px;
        }
      }
    }
    @include mq-above(md) {
      margin: 0 10px;
      border-width: 3px;
      width: 55px;
      height: 55px;
      &:hover {
        border-color: $key-color;
        color: $key-color;
        opacity: 1;
        &.prev:before,
        &.next:before {
          border-color: $key-color;
        }
      }
    }
  }
  .dots {
    border: none;
    @include mq-above(md) {
      &:hover {
        color: $border-color-gray;
      }
    }
  }
  .src-text {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  .prev,
  .next {
    .src-text {
      @include mq-above(md) {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
      }
    }
  }
}
.post-pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px 0 0 0;
  @include mq-above(md) {
    justify-content: center;
  }
  .prev-post,
  .next-post {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $border-color-gray;
    width: calc(50% - 5px);
    height: 52px;
    color: $border-color-gray;
    text-decoration: none;
    @include mq-above(md) {
      border-width: 3px;
      font-size: 2.6rem;
      width: 280px;
      height: 75px;
      &:hover {
        border-color: $key-color;
        color: $key-color;
        opacity: 1;
        &:before {
          border-color: $key-color;
        }
      }
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      display: block;
      margin: auto 0;
      width: 6px;
      height: 6px;
      @include mq-above(md) {
        width: 12px;
        height: 12px;
      }
    }
  }
  .prev-post {
    margin-right: auto;
    @include mq-above(md) {
      margin-right: 5px;
    }
    &:before {
      left: 8px;
      border-top: 1px solid $border-color-gray;
      border-left: 1px solid $border-color-gray;
      transform: rotate(-45deg);
      @include mq-above(xxs) {
        left: 10px;
      }
      @include mq-above(md) {
        left: 15px;
        border-width: 3px;
      }
    }
  }
  .next-post {
    margin-left: auto;
    @include mq-above(md) {
      margin-left: 5px;
    }
    &:before {
      right: 8px;
      border-bottom: 1px solid $border-color-gray;
      border-right: 1px solid $border-color-gray;
      transform: rotate(-45deg);
      @include mq-above(xxs) {
        right: 10px;
      }
      @include mq-above(md) {
        right: 15px;
        border-width: 3px;
      }
    }
  }
}
