/**
 * Footer Style
 */

.footer {
  position: relative;
  @include mq-above(md) {
  }
}
.bg-dolphin {
  position: absolute;
  left: 50%;
  top: -70px;
  transform: translate(-140px, 0);
  display: block;
  width: 76px * .5;
  height: 56px * .5;
  background-image: url(../img/shared/bg_dolphin.png);
  background-repeat: no-repeat;
  background-size: cover;
  @include mq-above(md) {
    left: 50%;
    top: -110px;
    transform: translate(-520px, 0);
    width: 76px;
    height: 56px;
  }
}
.footer-contact {
  margin-bottom: 30px;
  @include mq-above(md) {
    margin-bottom: 60px;
  }
  &-text {
    margin-top: 0;
    color: $text-color-white;
    text-align: center;
    @include mq-above(md) {
      font-size: 2.6rem;
      line-height: (40/26);
    }
  }
  &-button {
    display: flex;
    justify-content: center;
  }
}
.footer-link-list {
  list-style: none;
  margin: 0 -20px;
  padding-left: 0;
  display: flex;
  justify-content: center;
  position: relative;
  > li {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: 1.3;
    @include mq-above(md) {
      font-size: 1.6rem;
      line-height: 1.15;
    }
    &:nth-child(n+2) {
      border-left: 1px solid #fff;
    }
  }
}
.footer-link {
  color: $text-color-white;
  text-decoration: none;
}
.copyright {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  color: $text-color-white;
  font-size: 1.3rem;
  text-align: center;
  @include mq-above(md) {
    font-size: 1.6rem;
  }
}
.page-top {
  display: block;
  border: 1px solid #ffffff;
  border-radius: 50% 0 0 50%;
  border-width: 1px 0 1px 1px;
  width: 55px;
  height: 50px;
  background-color: $key-color;
  color: $text-color-white;
  font-size: 1.4rem;
  line-height: 1;
  text-decoration: none;
  @include mq-above(md) {
    width: 69px;
    height: 64px;
    font-size: 1.7rem;
  }
  &-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    width: 45px;
    height: 100%;
    @include mq-above(md) {
      width: 60px;
    }
    > img {
      width: 25px;
      @include mq-above(md) {
        width: auto;
      }
    }
    > span {
      margin-top: 6px;
    }
  }
}
